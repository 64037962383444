<template>
  <AuthForm :title="t('confirm.email.unsubscribe')">
    <template v-slot:top>
      <span>Пожалуйста, введите полученный код</span>
    </template>
    <template v-slot:inputs>
      <AppInput v-model="vc$.code.$model"
                :label="t('verification.code')"
                id="f-code"
                :class="setValidationStatusClass(vc$.code)"
                :element="vc$.code" @blur="vc$.code.$touch" />
    </template>
    <template v-slot:buttons>
      <AppButton
          class="auth-page__btn flex"
          :disabled="vc$.$invalid"
          @click="$emit('approve-unsubscribe-code', codeFromEmail)">
        <span>{{ t("approve") }}</span>
      </AppButton>
    </template>
  </AuthForm>
</template>

<script>
import { t } from "@/utils/translate";
import { ref } from "vue";
import AuthForm from "./AuthForm.vue";

import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";



import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { setValidationStatusClass } from "@/utils/validation";

import { useRoute } from 'vue-router'

export default {
  components: { AppInput, AppButton, AuthForm},
  emits: ['approve-unsubscribe-code', 'change-form'],
  setup(_, ctx) {
    const codeFromEmail = ref("");
    const cRules = {
      code: { required },
    };

    const route = useRoute()

    if (route.params.code) {
      ctx.emit('change-form', 'codeUnsubscribeVerification')
      codeFromEmail.value = route.params.code
    }

    const vc$ = useVuelidate(cRules, {
      code: codeFromEmail,
    });

    return { t, setValidationStatusClass, vc$, codeFromEmail }
  }
}
</script>

<style lang="scss">
.n-auth-code-v {
  &__title {
    margin-bottom: 30px;
  }

  &__btn {
    background-color: #fff;
    color: $accent-1;
    border: 2px solid $accent-1;

    &:hover {
      color: #fff;
    }
  }
}
</style>