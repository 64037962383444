<template>
  <div class="parent-event-planning">
    <AppBackButton :linkMode="true" link="/user/salon" />

    <AppTitle mode="h3">
      <template v-slot:title>{{ t("event.planning") }}</template>
    </AppTitle>

    <AppInput
      v-model="us$.name.$model"
      :label="`${t('set.salon.name')} *`"
      :class="setValidationStatusClass(us$.name)"
      @blur="us$.name.$touch"
      :element="us$.name"
      id="us-name"
    />

    <AppTextarea
      v-model="us$.description.$model"
      :placeholder="`${t('event.announcement')} *`"
      :class="setValidationStatusClass(us$.description)"
      @blur="us$.description.$touch"
      :element="us$.description"
      id="us-description"
    ></AppTextarea>

    <div class="parent-event-planning__box">
      <p>{{ t("set.event.type") }}:</p>
      <AppRadio
        v-for="(t, i) in salonTypes"
        :key="i"
        :id="`type-${i}`"
        :label="t.name"
        :value="t.typeValue"
        name="salon-type"
        v-model="salonType"
        :checked="salonType === t.typeValue"
      ></AppRadio>
    </div>

    <div class="parent-event-planning__box" v-if="Number(salonType) === 1">
      <p>{{ t("set.link.description") }}: *</p>
      <AppInput
        v-model="link$.link.$model"
        :label="`${t('external.link')}`"
        id="externalLink"
        :class="setValidationStatusClass(link$.link)"
        @blur="link$.link.$touch"
        :element="link$.link"
      />
      <AppInput
        v-model="link$.linkDescription.$model"
        :label="`${t('description')}`"
        id="externalLinkDescription"
        :class="setValidationStatusClass(link$.linkDescription)"
        @blur="link$.linkDescription.$touch"
        :element="link$.linkDescription"
      />
    </div>

    <div class="parent-event-planning__box">
      <p>{{ t("show.in.salon") }}?</p>
      <AppSwitch
        @switch="published = !published"
        :chosed="published"
        :okLabel="`${t('show')}`"
        :nokLabel="`${t('not.show')}`"
      ></AppSwitch>
    </div>

    <div
      class="parent-event-planning__box"
      :class="{ invalid: us$.date.$dirty && us$.date.$invalid }"
    >
      <p>{{ t("date") }} *</p>
      <datePicker
        v-model="us$.date.$model"
        :element="us$.date"
        id="us-date"
        @blur="us$.date.$touch"
        autoApply
        textInput
        locale="ru"
      />
      <span>{{ t("required.field") }}</span>
    </div>

    <div class="parent-event-planning__buttons">
      <ColoredButton
        v-if="Number(salonType) === 0"
        @click="save"
        :disabled="us$.$invalid"
      >
        <span>{{ t("save") }}</span>
      </ColoredButton>

      <ColoredButton
        v-if="Number(salonType) === 1"
        @click="save"
        :disabled="us$.$invalid || link$.$invalid"
      >
        <span>{{ t("save") }}</span>
      </ColoredButton>

      <NotAccentedButton @click="cancel">
        <span>{{ t("cancel") }}</span>
      </NotAccentedButton>
    </div>

    <div class="parent-event-planning__delete" v-if="editMode">
      <AppTooltip>
        <p>
        {{ t("event.remove.tooltip") }}
      </p>
      </AppTooltip>
     
      <NotAccentedButton @click="remove">
        <span>{{ t("delete") }}</span>
      </NotAccentedButton>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { required, maxLength } from "@vuelidate/validators";
import { setValidationStatusClass } from "@/utils/validation";

import { t } from "@/utils/translate";
import { customAxios } from "@/service/customAxios";
import { capitalizeFirstLetter, salonTypes } from "@/utils/common";

import { useUserStore } from "@/store/userStore";
import { usePopupStore } from "@/store/popupStore";
import { useRouter } from "vue-router";

import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import AppInput from "@/elements/AppInput.vue";
import AppTitle from "@/elements/AppTitle.vue";
import useVuelidate from "@vuelidate/core";

import ColoredButton from "@/elements/ColoredButton.vue";
import AppRadio from "@/elements/AppRadio.vue";
import AppBackButton from "@/elements/AppBackButton.vue";

import NotAccentedButton from "@/elements/NotAccentedButton.vue";
import AppSwitch from "@/elements/AppSwitch.vue";
import AppTextarea from "@/elements/AppTextarea.vue";

import AppTooltip from "@/components/AppTooltip.vue";

export default {
  setup() {
    const { showPopup } = usePopupStore();

    const userStore = useUserStore();
    const { editedWebinarData } = storeToRefs(userStore);
    const { addEditedWebinarData } = userStore;

    const router = useRouter();

    const salonDate = ref(null);
    const id = ref(null);
    const externalLink = ref("");
    const salonName = ref("");
    const salonDescription = ref("");
    const salonType = ref(0);
    const externalLinkDescription = ref("");
    const published = ref(false);

    function clear() {
      salonName.value = "";
      salonDescription.value = "";
      salonType.value = 0;
      salonDate.value = null;
      externalLink.value = "";
      externalLinkDescription.value = "";
      published.value = false;
      id.value = null;
    }

    const editMode = computed(() => !!editedWebinarData.value);

    const salonRules = {
      name: { required, max: maxLength(1500) },
      description: { required, max: maxLength(1500) },
      date: { required },
    };

    function setDateToEdit(d) {
      salonName.value = d.salonName;
      salonDescription.value = d.salonDescription;
      salonType.value = d.salonType;
      salonDate.value = d.salonDate;
      externalLink.value = d.externalLink;
      externalLinkDescription.value = d.externalLinkDescription;
      published.value = d.published;
      id.value = d.id;
    }

    const linkRules = {
      link: { required, max: maxLength(200) },
      linkDescription: { required, max: maxLength(1500) },
    };

    const us$ = useVuelidate(salonRules, {
      name: salonName,
      description: salonDescription,
      date: salonDate,
    });

    const link$ = useVuelidate(linkRules, {
      link: externalLink,
      linkDescription: externalLinkDescription,
    });

    async function editSalon(data) {
      try {
        await customAxios.putWithAuth(`salon/${id.value}`, data);
        showPopup(t("event.edited.success"), "success");
      } catch (e) {
        showPopup(t("something.went.wrong"), "error");
      }
    }

    async function saveSalon(data) {
      try {
        await customAxios.post(`salon`, data);
        showPopup(t("event.send.success"), "success");
      } catch (e) {
        showPopup(t("something.went.wrong"), "error");
      }
    }

    async function save() {
      const data = {
        salonName: capitalizeFirstLetter(salonName.value),
        salonDescription: capitalizeFirstLetter(salonDescription.value),
        salonType: salonType.value,
        salonDate: salonDate.value,
        externalLink: externalLink.value,
        externalLinkDescription: externalLinkDescription.value,
        published: published.value,
      };
      if (editedWebinarData.value) {
        await editSalon(data);
        addEditedWebinarData(null);
        clear();
        router.push({ path: "/user/salon" });
      } else {
        await saveSalon(data);
      }
    }

    async function remove() {
      try {
        await customAxios.delete(`salon/${id.value}`);
        showPopup(t("event.deleted.success"), "success");
      } catch (e) {
        showPopup(t("something.went.wrong"), "error");
      }
      router.push({ path: "/user/salon" });
    }

    function cancel() {
      if (editedWebinarData.value) {
        addEditedWebinarData(null);
      }
      clear();
      router.push({ path: "/user/salon" });
    }

    onMounted(() => {
      if (editedWebinarData.value) {
        setDateToEdit(editedWebinarData.value);
      }
    });

    return {
      cancel,
      editMode,
      us$,
      setValidationStatusClass,
      externalLink,
      externalLinkDescription,
      salonType,
      salonTypes,
      t,
      published,
      save,
      salonDate,
      link$,
      remove,
    };
  },
  components: {
    NotAccentedButton,
    AppInput,
    AppTitle,
    ColoredButton,
    AppRadio,
    AppBackButton,
    AppSwitch,
    AppTextarea,
    AppTooltip
},
};
</script>

<style lang="scss">
.parent-event-planning {
  h3 {
    margin-bottom: 40px;
  }

  textarea {
    margin-top: 20px;
  }

  &__box {
    margin: 20px 0;
    border-radius: 8px;
    padding: 16px;
    box-shadow: inset 0 0 0 1px #dad7d5;
    transition: all 0.2s;

    p {
      margin-bottom: 20px;
    }

    span {
      display: none;
      color: $error;
      padding-top: 8px;
    }

    &.invalid {
      input {
        border-color: $error;
      }

      span {
        display: block;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button {
      margin: 8px;
    }
  }

  &__delete {
    border-top: 1px solid $error;
    margin-top: 40px;
  }

  .app-back-button {
    display: flex;
  }
}
</style>
