<template>
  <UserContentWrapper :title="t('change.bio')">
    <div class="n-user-set-bio">
      <AppBackButton
        :linkMode="true"
        link="/user/settings"
        class="n-user-set-bio__back"
      />

      <AppInput
        v-model="firstname"
        id="u-name"
        :label="t('name')"
        class="n-user-set-bio__input"
      />
      <AppInput
        v-model="lastname"
        id="u-lastname"
        :label="t('surname')"
        class="n-user-set-bio__input"
      />

      <AppButton
        class="n-user-set-bio__save"
        @click="
          $emit('changeBio', { firstname: firstname, lastname: lastname })
        "
        :disabled="!firstname && !lastname"
        >{{ t("save") }}
      </AppButton>
    </div>
  </UserContentWrapper>
</template>

<script>
import AppInput from "@/elements/AppInput";
import AppButton from "@/elements/AppButton";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";

import { t } from "@/utils/translate";
import { ref } from "vue";

import AppBackButton from "@/elements/AppBackButton.vue";

export default {
  emits: ["changeBio"],
  components: { AppButton, AppInput, UserContentWrapper, AppBackButton },
  setup() {
    const firstname = ref("");
    const lastname = ref("");

    return { t, firstname, lastname };
  },
};
</script>

<style lang="scss">
.n-user-set-bio {
  &__input + .n-user-set-bio__input {
    margin-top: 16px;
  }

  &__save {
    @include button-default;
    margin-top: 30px;
  }
}
</style>
