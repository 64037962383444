<template>
  <AuthForm :title="t('entrance')">
    <template v-slot:top>
      <span>{{ t("new-to-app") }}</span>

      <TransparentButton @click="$emit('change-form', 'registration')">
        <span>{{ t("registration") }}</span>
      </TransparentButton>
    </template>

    <template v-slot:inputs>
      <AppInput
        inputType="email"
        v-model="vl$.email.$model"
        :label="t('email')"
        id="l-email"
        :class="setValidationStatusClass(vl$.email)"
        @blur="vl$.email.$touch"
        :element="vl$.email"
      />

      <AppInput
        inputType="password"
        v-model="vl$.password.$model"
        :label="t('password')"
        id="l-password"
        :class="setValidationStatusClass(vl$.password)"
        @blur="vl$.password.$touch"
        :element="vl$.password"
      />
    </template>

    <template v-slot:buttons>
      <ColoredButton
        :disabled="vl$.$invalid"
        @click="$emit('login', { username: lEmail, password: lPassword })"
      >
        <span>{{ t("come.in") }}</span>
      </ColoredButton>
      <ColoredButton @click="$emit('change-form', 'restorePassword')">
        <span>{{ t("forgot.password") }}</span>
      </ColoredButton>
    </template>
  </AuthForm>
</template>

<script>
import { ref } from "vue";
import { t } from "@/utils/translate";

import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { setValidationStatusClass } from "@/utils/validation";

import AuthForm from "./AuthForm.vue";
import AppInput from "@/elements/AppInput";
import TransparentButton from "@/elements/TransparentButton.vue";
import ColoredButton from "@/elements/ColoredButton.vue";

export default {
  components: { AppInput, AuthForm, TransparentButton, ColoredButton },
  emits: ["change-form", "login"],
  setup() {
    const lEmail = ref("");
    const lPassword = ref("");

    const lRules = {
      password: { required, min: minLength(6) },
      email: { required, email },
    };

    const vl$ = useVuelidate(lRules, {
      email: lEmail,
      password: lPassword,
    });

    return { t, vl$, lEmail, lPassword, setValidationStatusClass };
  },
};
</script>
