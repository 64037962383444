<template>
  <UserContentWrapper :title="t('courses')">
    <div class="user-courses">
      <AppBackButton
        :linkMode="true"
        to="/user/panel"
      />

      <StateWrapper
        :content="courses"
        :loadingStatus="loadingStatus"
        :checkLogin="true"
      >
        <div class="user-courses__content">
          <CoursePreview v-for="v in courses" :key="v.id" :element="v" />
        </div>
      </StateWrapper>

      <router-link to="/courses" class="user-courses__go" v-if="!courses.length">
        {{ t('to.courses.list') }}
        <AppIcon icon="arrow-icon" />
      </router-link>
    </div>
  </UserContentWrapper>
</template>

<script>
import CoursePreview from "@/components/CoursePreview.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";

import { t } from "@/utils/translate";

import { customAxios } from "@/service/customAxios";
import { onMounted, ref } from "vue";
import StateWrapper from "./StateWrapper.vue";
import AppIcon from "@/elements/AppIcon.vue";

export default {
  setup() {
    const courses = ref([]);
    const loadingStatus = ref("loading");

    async function getMyCourses() {
      try {
        const r = await customAxios.getWithAuth(`course/my`);

        console.log(r.data);
        loadingStatus.value = "loaded";

        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }

    onMounted(async () => {
      courses.value = await getMyCourses();
    });

    return {
      loadingStatus,
      courses,
      t,
    };
  },
  components: {
    CoursePreview,
    UserContentWrapper,
    AppBackButton,
    StateWrapper,
    AppIcon
},
};
</script>

<style lang="scss">
.user-courses {

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }

  &__go {
    @include button-default;
    text-decoration: none;
    padding: 8px 16px;
    margin-top: 30px;
    @include flex-center;

    svg {
      stroke: #fff;
    }
  }

  @media (max-width: 640px) {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}
</style>
