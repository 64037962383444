export const texts = {
  promo: {
    subtitle: "ФГБОУ ВО ИГМУ Минздрава России",
    title: "Кафедра общественного здоровья и здравоохранения",
    description: ``,
  },
};

export const companyInformation = {
  contacts: {
    phone: "+7 (3952) 24-38-77",
    email: "irkafoz@mail.ru",
    address: `664003 г. Иркутск, ул. Красного Восстания, 2, каб. 14`,
  },
};
