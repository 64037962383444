<template>
  <AppButton class="transparent-button">
    <slot></slot>
  </AppButton>
</template>

<script>
import AppButton from './AppButton.vue';

export default {
    setup() {
        return {};
    },
    components: { AppButton }
};
</script>

<style lang="scss">
.transparent-button {
    background-color: transparent;
    color: $accent-1;

    &:hover {
        box-shadow: 0 2px 0 0 $accent-1;
    }
}
</style>