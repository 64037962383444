<template>
  <AppButton
    @click.prevent="$emit('toggleFavoriteStatus')"
    class="like-button flex"
  >
    <AppIcon icon="like-solid" v-if="element.inFavorite" />
    <AppIcon icon="like-icon" v-else />
  </AppButton>
</template>

<script>
import AppIcon from "@/elements/AppIcon";
import AppButton from "@/elements/AppButton";
import { t } from "@/utils/translate";

export default {
  emits: ["toggleFavoriteStatus"],
  components: { AppIcon, AppButton },
  props: {
    element: {
      type: Object,
      required: false,
    },
  },
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.like-button {
  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  svg {
    stroke: $accent-1;
    fill: $accent-1;
  }
}
</style>
