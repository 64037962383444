export const customStorage = {
  baseName: "app-",
  set(name, data) {
    window.localStorage.setItem(`${this.baseName}${name}`, data);
  },
  get(name) {
    return window.localStorage.getItem(`${this.baseName}${name}`);
  },
  remove(name) {
    window.localStorage.removeItem(`${this.baseName}${name}`);
  },
};
