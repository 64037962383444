<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Конференции</v-tab>
      <v-tab value="3" >Видео</v-tab>
      <v-tab value="4" >Файлы</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card>
          <v-card-title class="card_ligth_header ma-0 pa-0" >
            <v-row cols=6 class="pa-2 ma-2">
              <v-col cols="12">
                <h3>События</h3>
              </v-col>

              <v-col cols=12>
                <v-text-field
                    v-model="eventSearchValue"
                    label="Что ищем"
                    variant="outlined"
                    clearable
                    rounded
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <AppButton
                    class="n-parametrs__opener d-flex flex__ai-c"
                    @click="createEvent">
                  <span>Добавить</span>
                </AppButton>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text clas="ma-0 pa-0" >
            <v-row>
              <v-col cols="12">
                <Vue3EasyDataTable
                    class="mb-16 pb-16"
                    :headers="eventHeaders"
                    :items="events"
                    alternating
                    show-index
                    :search-value="eventSearchValue"
                    :rows-per-page-message="'Записей на страницу'"
                    :rows-per-page = "20"
                    :rows-items="[20,50,100]"
                    :rows-of-page-separator-message	="'из'"
                    buttons-pagination
                    :empty-message="'Данные не добавлены'"
                >


                  <template #item-published="{published}">
                    <p v-if="published">Да</p> <p v-else>Нет</p>
                  </template>
                  <template #item-eventDate="{eventDate}">
                    {{ eventDate.replace('T', ' ')}}
                  </template>

                  <template #item-actions="item">
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="openReports(item)">
                      <span>Доклады</span>
                    </AppButton>
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="editEvent(item)">
                      <span>Редактировать</span>
                    </AppButton>
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="deleteEvent(item)">
                      <span>Удалить</span>
                    </AppButton>
                  </template>
                </Vue3EasyDataTable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-window-item>
      <v-window-item :value="2" class="align-self-stretch">
        <v-card>
          <v-card-title class="card_ligth_header ma-0 pa-0" >
            <v-row cols=6 class="pa-2 ma-2">
              <v-col cols="12">
                <h3>Доклады</h3>
              </v-col>

              <v-col cols=12>
                <v-text-field
                    v-model="eventReportSearchValue"
                    label="Что ищем"
                    variant="outlined"
                    clearable
                    rounded
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <AppButton
                    class="n-parametrs__opener d-flex flex__ai-c"
                    @click="createEventReport">
                  <span>Добавить</span>
                </AppButton>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text clas="ma-0 pa-0" >
            <v-row>
              <v-col cols="12">
                <Vue3EasyDataTable
                    class="mb-16 pb-16"
                    :headers="eventReportHeaders"
                    :items="eventReports"
                    alternating
                    show-index
                    :search-value="eventReportSearchValue"
                    :rows-per-page-message="'Записей на страницу'"
                    :rows-per-page = "20"
                    :rows-items="[20,50,100]"
                    :rows-of-page-separator-message	="'из'"
                    buttons-pagination
                    :empty-message="'Данные не добавлены'"
                >


                  <template #item-published="{published}">
                    <p v-if="published">Да</p> <p v-else>Нет</p>
                  </template>
                  <template #item-reportDate="{reportDate}">
                    {{ reportDate.replace('T', ' ')}}
                  </template>

                  <template #item-actions="item">
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="editEventReport(item)">
                      <span>Редактировать</span>
                    </AppButton>
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="deleteEventReport(item)">
                      <span>Удалить</span>
                    </AppButton>
                  </template>
                </Vue3EasyDataTable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-window-item>
      <v-window-item value="3" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Видео</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="videoSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createVideo">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="videoFileHeaders"
                  :items="sourceVideoFiles"
                  alternating
                  show-index
                  :search-value="videoSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "20"
                  :rows-items="[20,50,100]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-processed="{processed, isExternalLink, sourceHlsIndex,externalLinkUrl, posterName, }">
                  <v-chip
                      v-if="isExternalLink===false"
                      small
                      size="small"
                      @click="playInternalVideo(sourceHlsIndex, posterName)"
                  >
                    <p v-if="!!processed">Видео обрабатывается</p>
                    <p v-else>Видео обработано</p>
                    <template v-slot:prepend>
                      <v-progress-circular v-if="!!processed"
                                           color="grey-lighten-4"
                                           indeterminate
                                           size="13"
                      ></v-progress-circular>
                      <v-icon v-else icon="mdi-play"></v-icon>
                    </template>
                  </v-chip>

                  <v-chip
                      v-else
                      small
                      size="small"
                  >
                    <a :href="externalLinkUrl" target="_blank">{{ externalLinkUrl }} </a>
                  </v-chip>
                </template>

                <template #item-isExternalLink="{isExternalLink}">
                  <p v-if="isExternalLink">Внешняя ссылка</p>
                  <p v-else>Видео в системе</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editVideoMethod(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteVideoMethod(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="4" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Файлы</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="fileSearchBody"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createFile">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="fileHeaders"
                  :items="files"
                  alternating
                  show-index
                  :search-value="fileSearchBody"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="downloadFile(item)">
                    <span>Скачать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteFile(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

    </v-window>
  </div>
  <v-dialog v-model="eventDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование конференции</h5>
        <h5 v-else>Добавление конференции</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="eventForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="eventName"
                    :rules="eventNameRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Краткий анонс*"
                    v-model="eventAnnonce"
                    :rules="eventAnnonceRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="pb-16" style="height: 400px;">
                <quill-editor
                    v-model:value="eventDescription"
                />
              </v-col>
              <v-col cols="12">
                <v-label>Дата проведения*</v-label>
                <datePicker
                    v-model="eventDate"
                    autoApply
                    inline
                    textInput
                    locale="ru"
                />
                <v-alert
                    v-if="eventDateTimeAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                >
                  Укажите дату проведения !
                </v-alert>
              </v-col>

              <v-col cols="12" class="pt-16 pb-2">
                <v-autocomplete
                    v-model="attachmentFiles"
                    :items="files"
                    clearable
                    return-object
                    multiple
                    outlined
                    dense
                    small-chips
                    chips
                    closable-chips
                    item-title="fileDescription"
                    label="Файлы"
                    v-on:click:clear="attachmentFiles = []"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.fileDescription.length>30 ? item.raw.fileDescription.substring(0, 28) + '...' : item.raw.fileDescription"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.fileDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>


              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="published"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeEvent"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveEvent"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="eventReportDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование доклада конференции</h5>
        <h5 v-else>Добавление доклада конференции</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="eventReportForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="reportName"
                    :rules="eventReportNameRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-2" style="height: 400px;">
                <quill-editor
                    v-model:value="reportDescription"
                />
              </v-col>
              <v-col cols="12" class="pt-16 pb-2">
                <v-autocomplete
                    v-model="videoFiles"
                    :items="sourceVideoFiles"
                    clearable
                    return-object
                    multiple
                    outlined
                    dense
                    small-chips
                    chips
                    closable-chips
                    item-title="videoDescription"
                    label="Видео"
                    v-on:click:clear="videoFiles = []"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.videoDescription.length>30 ? item.raw.videoDescription.substring(0, 28) + '...' : item.raw.videoDescription"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.videoDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="ma-2">
                <v-select
                    :items="computedWeigth"
                    v-model="eventWeight"
                    label="Вес доклада"/>
              </v-col>
              <v-col cols="12">
                <v-label>Дата проведения*</v-label>
                <datePicker
                    v-model="reportDate"
                    autoApply
                    inline
                    textInput
                    locale="ru"
                />
                <v-alert
                    v-if="eventReportDateTimeAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                >
                  Укажите дату проведения !
                </v-alert>
              </v-col>
              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="published"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeEventReport"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveEventReport"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <loader-ring></loader-ring>
  </v-dialog>

  <v-dialog v-model="fileDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Добавление файла</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="fileForm">
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="fileDescription"
                    :rules="eventReportNameRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите описание файла"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-file-input
                    show-size
                    v-model="fileBody"
                    ref="fileBody"
                    :rules="fileRules"
                    accept="image/png, image/bmp, image/jpeg,.pdf"
                    clearable
                    variant="outlined"
                    counter
                    rounded
                    label="Загрузить файл*"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0 & uploadPercentage<100"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeFileForm"
        >
          Закрыть
        </v-btn>
        <v-btn
            rounded
            variant="outlined"
            @click="saveFile"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>


  <v-dialog v-model="videoFileDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование видео</h5>
        <h5 v-else>Добавление видео</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="videoForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="videoDescription"
                    :rules="descriptionRules"
                    :counter="500"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12" v-if="!this.id">
                <v-row class="px-4" fluid>
                  <v-switch v-model="isExternalLink" :label="'Тип'" color="success">
                  </v-switch>
                  <v-chip class="ma-2" v-if="isExternalLink"> Внешняя ссылка</v-chip>
                  <v-chip v-else class="ma-2"> Загрузить видео</v-chip>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="!!isExternalLink">
                <v-text-field
                    label="Внешняя ссылка"
                    v-model="externalLinkUrl"
                    hint="Укажите внешнюю ссылку на видео"
                    :counter="250"
                    variant="outlined"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-else-if="(!isExternalLink)&&(!this.id)">
                <v-file-input
                    show-size
                    v-model="videoFileBody"
                    ref="videoFileBody"
                    :rules="fileRules"
                    accept="video/mp4,video/x-m4v,video/*"
                    clearable
                    variant="outlined"
                    counter
                    rounded
                    label="Загрузить файл*"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0 & uploadPercentage<100"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
              <v-col cols="12" v-if="uploadPercentage===100">
                <p>Файл загружен, идет обработка. Окно можно закрыть. После обработки видео будет доступно для просмотра.</p>
              </v-col>




            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeVideoFileForm"
        >
          Закрыть
        </v-btn>
        <v-btn
            v-if="!!isExternalLink"
            rounded
            variant="outlined"
            @click="saveSingleVideoFile"
        >
          Сохранить
        </v-btn>
        <v-btn
            v-else-if="!isExternalLink"
            rounded
            variant="outlined"
            color="primary"
            style="margin-left: 5px"
            :disabled="downloadAnimation"
            @click="saveSingleVideoFile"
        >
          Сохранить
          <v-progress-circular
              v-if="downloadAnimation"
              indeterminate
              color="amber"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="showVideoFileInput" persistent scrollable>
    <v-form ref="videoFileForm">
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Загрузка видео</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                    show-size
                    v-model="videoFileBody"
                    ref="videoFileBody"
                    :rules="fileRules"
                    accept="video/mp4,video/x-m4v,video/*"
                    clearable
                    variant="outlined"
                    counter
                    rounded
                    label="Загрузить файл*"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0 & uploadPercentage<100"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
              <v-col cols="12" v-if="uploadPercentage===100">
                <p>Файл загружен, идет обработка. Окно можно закрыть. После обработки видео будет доступно для просмотра.</p>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              color="primary-dark"
              style="margin-left: 5px"
              @click="closeVideoFileInput"
          >
            Закрыть
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              color="primary"
              style="margin-left: 5px"
              :disabled="downloadAnimation"
              @click="saveVideoFile"
          >
            Сохранить
            <v-progress-circular
                v-if="downloadAnimation"
                indeterminate
                color="amber"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <v-dialog v-model="showVideo" persistent scrollable>
    <v-card>
      <v-card-title>
        <h5>{{selectedVideo.originalFileName}}</h5>
      </v-card-title>
      <v-divider></v-divider>
      <Vue3VideoPlayer
          :src="videoSrc"
          :cover="posterSrc"
          :core="HLSCore"
      ></Vue3VideoPlayer>

      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-btn color="accent"    variant="outlined"  block @click="destroy(null)">Закрыть</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
import HLSCore from "@cloudgeek/playcore-hls";
import {customStorage} from "@/service/customStorage";
import download from "js-file-download";
import {userService} from "@/service/user.service";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminNewsPage',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    files:[],
    fileSearchBody:'',
    fileHeaders: [
      { text: "id", value: "id" , width: 10},
      { text: "Название", value: "fileName" , sortable: true },
      { text: "Описание", value: "fileDescription" , sortable: true },
      { text: "Дата добавления", value: "createDateTime" , sortable: true},
      { text: "Действие", value: "actions", width: 170},
    ],
    fileName:'',
    fileDialog:false,
    fileBody:[],
    fileDescription:'',
    attachmentFiles:[],




    eventDialog:false,
    eventName:'',
    eventNameRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    eventAnnonce:'',
    eventAnnonceRules: [
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    eventDescription:'',
    eventDate:'',
    eventDateTimeAlert:false,
    published:false,

    eventHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "eventName" , sortable: true  },
      { text: "Дата проведения", value: "eventDate" , sortable: true},
      { text: "Опубликован", value: "published" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
     events:[],
    sourceVideoFiles:[],


    reportName:'',
    eventReportNameRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],

    reportDescription:'',
    reportDate:'',
    eventId:'',
    eventWeight:0,
    videoFiles:[],
    eventReportDialog:false,
    eventReportDateTimeAlert:false,
    eventReportHeaders: [
      { text: "id", value: "id" },
      { text: "Название", value: "reportName" , sortable: true  },
      { text: "Вес", value: "eventWeight" , sortable: true  },
      { text: "Дата проведения", value: "reportDate" , sortable: true},
      { text: "Опубликован", value: "published" , sortable: true},
      { text: "Действие", value: "actions"},
    ],

    eventReports:[],






    id:'',
    tab:1,
    eventSearchValue:'',
    downloadPercentage:0,


    showLoader: false,
    title:'',
    titleRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],

    eventBody:'',
    eventTypeId:1,
    eventReportSearchValue:'',


    videoFileId:'',
    videoSearchValue:'',
    videoFileDialog: false,
    videoDescription:'',
    videoFileBody:[],
    videoSrc:'',
    posterSrc:'',
    players: {},
    HLSCore,
    volume: 80,
    selectedVideo: '',
    videoFileHeaders: [
      { text: "id", value: "id" , width: 10},
      { text: "Название", value: "videoDescription" , sortable: true , width: 350 },
      { text: "Дата добавления", value: "CreateDate" , sortable: true, width: 170},
      { text: "Тип", value: "isExternalLink" , sortable: true, width: 150},
      { text: "Статус", value: "processed", width: 170},
      { text: "Действие", value: "actions", width: 170},
    ],
    sourceId:'',
    showVideoFileInput:false,
    showVideo:false,
    fileRules: [
      (v) => !!v || "Необходимо прикрепить файл!",
    ],
    uploadPercentage:0,
    downloadAnimation:false,
    isActive:false,
    addVideoInPlaylistDialog:false,
    descriptionRules: [
      v => (v && v.length <= 500) || 'Значение до должно превышать 500 символов!',
    ],
    isExternalLink:false,
    externalLinkUrl:'',




  }),
  methods: {
    createFile(){
      this.id='';
      this.fileDialog=true
    },
    createVideo(){
      this.id='';
      this.videoFileDialog=true
    },
    closeVideoFileForm(){
      this.videoDescription = ''
      this.isExternalLink = false
      this.videoFileBody= []
      this.externalLinkUrl = ''
      this.uploadPercentage = 0
      this.videoFileDialog = false
    },
    deleteVideoMethod(item){
      customAxios
          .delete(
              "library/video/" + item.id
          )
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.sourceVideoFiles, item.id);
              this.sourceVideoFiles.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    closeFileForm(){
      this.fileBody= []
      this.uploadPercentage = 0
      this.fileDialog = false
    },
    saveFile() {
      this.$refs.fileForm.validate().then((result) => {
        if (result.valid) {
          let file = {
            fileDescription: capitalizeFirstLetter(this.fileDescription),
          }
            if (this.$refs.fileBody.files.length>0) {
              customAxios.post('attachment', file)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 201) {
                      this.files.push(res.data)
                      this.downloadAnimation = true;
                      let formData = new FormData();
                      formData.append("file", this.$refs.fileBody.files[0]);
                      this.axios
                          .post("https://zdrav-backend.geekbase.ru/api/attachment/file/" + res.data.id, formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              Authorization: "Bearer " + customStorage.get("user-token"),
                            },
                            onUploadProgress: function (progressEvent) {
                              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            }.bind(this)

                          }).then((res) => {
                        console.log("upload result: " + res)
                        if (res.status === 202) {
                          let index = getIndex(this.files, res.data.id);
                          this.files.splice(index, 1, res.data);
                          this.fileDescription = ''
                          this.fileBody = []
                          this.fileDialog = false
                          this.uploadPercentage = 0
                          this.downloadAnimation = false;
                        }
                      })
                          .catch((error) => {
                            this.downloadAnimation = false;
                            this.fileDialog = false
                            this.uploadPercentage = 0;
                            this.fileBody = [];
                            console.log("Error: " + error);
                          });
                    }
                  });
            } else {
              alert("Необходимо прикрепить файл!")
            }
        }
      })


    },


    saveSingleVideoFile() {
      this.$refs.videoForm.validate().then((result) => {
        if (result.valid) {
          let video = {
            isExternalLink: this.isExternalLink,
            externalLinkUrl: this.externalLinkUrl,
            videoDescription: capitalizeFirstLetter(this.videoDescription),
          }
          if ((this.isExternalLink && (this.externalLinkUrl.length > 1)) || ((!this.isExternalLink) && (this.id))) {
            if (this.id) {
              customAxios.putWithAuth('library/video/' + this.id, video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 202) {
                      let index = getIndex(this.sourceVideoFiles, res.data.id);
                      this.sourceVideoFiles.splice(index, 1, res.data);
                      this.videoDescription = ''
                      this.isExternalLink = false
                      this.videoFileBody = []
                      this.externalLinkUrl = ''
                      this.videoFileDialog = false
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            } else {
              customAxios.post('library/video', video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 201) {
                      this.sourceVideoFiles.push(res.data)
                      this.videoDescription = ''
                      this.isExternalLink = false
                      this.videoFileBody = []
                      this.externalLinkUrl = ''
                      this.videoFileDialog = false
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            }


          } else if (this.isExternalLink && (this.externalLinkUrl.length < 1)) {
            alert("Необходимо указать ссылку!")
          } else if (!this.isExternalLink) {

            if (this.$refs.videoFileBody.files.length>0) {
              customAxios.post('library/video', video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 201) {
                      this.sourceVideoFiles.push(res.data)
                      this.downloadAnimation = true;
                      let formData = new FormData();
                      formData.append("file", this.$refs.videoFileBody.files[0]);
                      this.axios
                          .post("https://zdrav-backend.geekbase.ru/api/video/upload/file/" + res.data.id, formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              Authorization: "Bearer " + customStorage.get("user-token"),
                            },
                            onUploadProgress: function (progressEvent) {
                              this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            }.bind(this)

                          }).then((res) => {
                        console.log("upload result: " + res)
                        if (res.status === 202) {
                          let index = getIndex(this.sourceVideoFiles, res.data.id);
                          this.sourceVideoFiles.splice(index, 1, res.data);
                          this.videoDescription = ''
                          this.isExternalLink = false
                          this.videoFileBody = []
                          this.externalLinkUrl = ''
                          this.videoFileDialog = false
                          this.uploadPercentage = 0
                          this.downloadAnimation = false;
                        }
                      })
                          .catch((error) => {
                            this.downloadAnimation = false;
                            this.videoFileDialog = false
                            this.uploadPercentage = 0;
                            this.videoFileBody = [];
                            console.log("Error: " + error);
                          });
                    }
                  });
            } else {
              alert("Необходимо прикрепить файл!")
            }

          }

        }
      })


    },
    editVideoMethod(item){
      this.id = item.id
      this.isExternalLink = item.isExternalLink
      this.externalLinkUrl= item.externalLinkUrl
      this.videoDescription = item.videoDescription
      this.videoFileDialog = true
    },
    viewCore(id, player) {
      console.log(id, player);
      this.players[id] = player;
    },
    play(id) {
      console.log('custom play: id =', id);
      this.players && this.players[id] && this.players[id].play();
    },
    destroy(id) {
      this.showVideo = false;
      this.players && this.players[id] && this.players[id].destroy();
    },
    closeVideoFileInput(){
      this.showVideoFileInput = false;
      this.uploadPercentage = 0;
      this.videoFileBody=[];
      this.downloadAnimation = false;
    },
    playInternalVideo(videoSrc, posterSrc){

      this.videoSrc = "https://zdrav.geekbase.ru/video" + videoSrc
      this.posterSrc ="https://zdrav.geekbase.ru/video" + posterSrc
      this.showVideo = true

    },
    //
    deleteEventReport(item){
      customAxios.delete("event/report/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.eventReports, item.id);
              this.eventReports.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    openReports(item){
      this.eventId = item.id;
      customAxios.getWithAuth('event/report/'+item.id)
          .then(response => {
            if (response.status === 200) {
              this.eventReports = response.data;
              this.tab = 2;
            }  else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })

    },
    createEventReport(){
      this.id='';
      this.eventReportDialog=true
    },

    closeEventReport(){
      this.id='';
      this.reportName = '';
      this.reportDescription = '';
      this.reportDate = '';
      this.published = false;
      this.eventReportDateTimeAlert = false;
      this.videoFiles = [];
      this.eventReportDialog=false;
    },
    editEventReport(item){
      this.id=item.id;
      this.reportName = item.reportName;
      this.reportDescription = item.reportDescription;
      this.reportDate = item.reportDate;
      this.published = item.published;
      this.eventWeight = item.eventWeight;
      this.videoFiles = item.videoFiles;
      this.eventReportDialog=true;
    },
    saveEventReport() {
      this.$refs.eventReportForm.validate().then(responce =>{
        if(this.reportDate==''){
          this.eventReportDateTimeAlert=true;
        } else {
          this.eventReportDateTimeAlert=false;
        }
        if(responce.valid && this.eventReportDateTimeAlert==false){
          const userTimezoneOffset =
              new Date(this.reportDate).getTimezoneOffset() * 60000;

          let report = {
            reportName:capitalizeFirstLetter(this.reportName),
            reportDescription:this.reportDescription,
            published: this.published,
            eventWeight: this.eventWeight,
            videoFiles:this.videoFiles,
            eventId:this.eventId,
            reportDate: new Date(new Date(this.reportDate).getTime() - userTimezoneOffset),
          }

          if (this.id) {
            customAxios.putWithAuth('event/report/' + this.id, report)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.eventReports, response.data.id)
                    this.eventReports.splice(index, 1, response.data)
                    this.closeEventReport()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('event/report', report)
                .then(response => {

                  if (response.status === 201) {
                    this.eventReports.push(response.data)
                    this.closeEventReport();
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },


    closeEvent(){
      this.id='';
      this.eventName = '';
      this.eventAnnonce = '';
      this.eventDescription = '';
      this.attachmentFiles = [];
      this.eventDate = '';
      this.published = false;
      this.eventDateTimeAlert = false;
      this.eventDialog=false;
    },

    editEvent(item){
      this.id=item.id;
      this.eventName = item.eventName;
      this.attachmentFiles = item.attachmentFiles;
      this.eventAnnonce = item.eventAnnonce;
      this.eventDescription = item.eventDescription;
      this.published = item.published;
      this.eventDate = item.eventDate;
      this.eventDialog=true;
    },



    createEvent(){
      this.id='';
      this.eventDialog=true
    },

    saveEvent() {
      this.$refs.eventForm.validate().then(responce =>{
        if(this.eventDate==''){
          this.eventDateTimeAlert=true;
        } else {
          this.eventDateTimeAlert=false;
        }

        if(responce.valid && this.eventDateTimeAlert==false){
          const userTimezoneOffset =
              new Date(this.eventDate).getTimezoneOffset() * 60000;

          let event = {
            eventName:capitalizeFirstLetter(this.eventName),
            eventAnnonce:this.eventAnnonce,
            published: this.published,
            attachmentFiles: this.attachmentFiles,
            eventDescription:this.eventDescription,
            eventDate: new Date(new Date(this.eventDate).getTime() - userTimezoneOffset),
          }

          if (this.id) {
            customAxios.putWithAuth('event/' + this.id, event)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.events, response.data.id)
                    this.events.splice(index, 1, response.data)
                    this.closeEvent()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('event', event)
                .then(response => {

                  if (response.status === 201) {
                    this.events.push(response.data)
                    this.closeEvent();
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },

    downloadFile: function (item) {
      let config = {
        responseType: "blob",
        onDownloadProgress: function( progressEvent ) {
          this.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
        }.bind(this)

      };
      customAxios.get("attachment/file/" + item.randomFileName,
          config
      ).then((resp) => {
        download(resp.data, item.fileName);
      });
    },

    deleteEvent(item){
      customAxios.delete("event/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.events, item.id);
              this.events.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    deleteFile(item){
      customAxios.delete("attachment/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.files, item.id);
              this.files.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },


  },
  computed: {
    computedWeigth(){
      let cw = [];
      for (let i = 0; i <= 100; i++) {
        cw.push(i);
      }
      return cw;
    }
  },
  created: function () {
    this.showLoader=true

    function getEvents() {
      return customAxios.getWithAuth('event')
    }

    function getVideos() {
      return customAxios.getWithAuth('library/video')
    }

    function getFiles() {
      return customAxios.getWithAuth('attachment')
    }



    Promise.all([
        getEvents(),
        getVideos(),
        getFiles()
    ]).then(results => {

      if(results[0].status===200){
        this.events = results[0].data;
      }
      if(results[1].status===200){
        this.sourceVideoFiles = results[1].data;
      }
      if(results[2].status===200){
        this.files = results[2].data;
      }
      this.showLoader=false
    }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}
.admin-lib {
  margin-bottom: 60px;

  &__checkboxies {
    text-align-last: left;
  }

  &__checkbox + .n-parametrs__checkbox {
    margin-top: 16px;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;
    background: $gradient;
    transform: translate(50%, -50%);
    border-radius: 50%;
    @include flex-center;
    min-width: 30px;
    min-height: 30px;
    padding: 4px;
    aspect-ratio: 1/1;
  }

  &__opener {
    @include button-default-small;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }

  &__table_button {
    @include button-default-for-table;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }


  svg {
    stroke: #fff;
  }

  &__parametrs {
    background-color: #f5f5f5;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
  }
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
