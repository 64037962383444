import { customAxios } from "@/service/customAxios";
import { customStorage } from "@/service/customStorage";
import { defineStore } from "pinia";

export const useQuestionnareStore = defineStore("questionnare", {
  state: () => ({
    questionnareId: null,
    currentUuids: JSON.parse(customStorage.get("questionnare-uuids")) || [],
    currentTemplate: null,
  }),
  getters: {
    currentTemplateUuid() {
      let result = null;
      if (this.currentUuids.length) {
        result = this.currentUuids.find(
          (v) => v.questionnareId === this.questionnareId
        );
      }
      return result;
    },
  },
  actions: {
    setCurrentTemplate(template) {
      this.currentTemplate = template;
    },
    setCurrentId(id) {
      this.questionnareId = id;
    },
    removeUuidFromUser(uuid) {
      let uuids = JSON.parse(customStorage.get("questionnare-uuids")) || [];
      if (uuids.length) {
        uuids.forEach((item) => {
          console.log(item);
          if (item.uuid.includes(uuid)) {
            console.log("UUID in stor");
            uuids.splice(item);
            console.log("len after delete: " + uuids.length);

            customStorage.set("questionnare-uuids", JSON.stringify(uuids));
          }
        });
      } else {
        console.log("Stor is empty");
      }
    },

    getQuestionnare(questionnareId, uuid) {
      const r = customAxios.post("checklist/attempt", {
        checklistId: questionnareId,
        uuid: uuid,
      });
      return r;
    },
    
    addNewUuid(questionnareId, uuid) {
      const prevUuids = JSON.parse(customStorage.get("questionnare-uuids"));
      const item = { questionnareId: questionnareId, uuid: uuid };

      if (prevUuids && prevUuids.length) {
        this.currentUuids = JSON.stringify([...prevUuids, ...item]);
      } else {
        this.currentUuids = JSON.stringify([...item]);
      }

      customStorage.set("questionnare-uuids", this.currentUuids);
    },
  },
});
