import "vuetify/lib/styles/main.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { router } from "@/service/router";
import { createVuetify} from 'vuetify'
import { createApp } from "vue";
import VueScrollTo from "vue-scrollto";
import { store } from "./store/index";
import App from "./App";
import axios from "axios";
import VueAxios from "vue-axios";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import lodash from "lodash";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "@fontsource/montserrat";
import { quillEditor } from 'vue3-quill'

import AvatarCropper from "vue-avatar-cropper";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import MasonryWall from "@yeger/vue-masonry-wall";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

const myLang = {
  dashboard: {
    btn: {
      play: "Запустить",
      pause: "Пауза",
      fullscreen: "Полноэкранный режим",
      exitFullscreen: "Выйти из полноэкранного режима",
      mute: "Выключить звук",
      unmute: "Включить звук",
      back: "Назад",
      pip: "Картинка в картинке",
    },
    settings: {
      autoplay: "Автопроигрывание",
      loop: "Повтор",
      speed: "Скорость",
      resolution: "Resolution",
    },
  },
  layers: {
    error: {
      title: "Ошибка!",
      2404: "Video Source Undefined",
      2502: "Media Network Error",
      2503: "Video Cannot DECODE",
      2504: "Видео не может воспроизвестись!",
      601: "Live видео не может воспроизвестись!",
    },
    loading: {
      msg: "Загрузка ...",
    },
  },
};

import { createPinia } from "pinia";
const pinia = createPinia();



//import colors from 'vuetify/lib/util/colors'

const app = createApp(App);

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import VueYandexMetrika from 'vue-yandex-metrika'

const vuetify = createVuetify({ components, directives})
app.use(quillEditor)
app.use(router);
app.use(vuetify)
app.use(pinia);
app.use(Vue3VideoPlayer, {lang: myLang});
app.use(MasonryWall);
app.use(lodash);
app.use(VueAxios, axios);
app.config.globalProperties.$axios = axios;
app.use(store);
app.use(AvatarCropper);
app.component("datePicker", Datepicker);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueAwesomePaginate);
app.use(VueScrollTo);
app.component("QuillEditor", QuillEditor);
app.mount("#app");
