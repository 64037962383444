<template>
  <div class="app-textarea">
    <textarea
      @blur="$emit('blur')"
      :id="id"
      :rows="rows || 10"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <div
      v-if="element && element.$dirty && !element.$model"
      class="app-textarea__error"
    >
      {{ t("please.enter.chars") }}
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    rows: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    element: {
      type: Object,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    return { t };
  },
};
</script>

<style lang="scss">
.app-textarea {
  overflow: hidden;
  // display: flex;

  textarea {
    resize: none;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba($text, 0.2);
    width: 100%;
    height: 100%;
    padding: 16px;

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px $accent-1;
    }
  }

  &.error {
    textarea {
      box-shadow: inset 0 0 0 2px $error;
    }

    textarea::placeholder {
      color: $error;
    }
  }

  &.correct {
    textarea {
      box-shadow: inset 0 0 0 2px #2ebd59;
    }
  }

  &__error {
    // padding: 16px 0;
    color: $error;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }
}
</style>
