<template>
  <div class="accordion-item" :class="{ opened: isOpen }" ref="itemRef">
    <AppButton
      class="accordion-item__top flex flex__ai-c"
      @click="$emit('open', id)"
    >
      <div class="accordion-item__top-slot"><slot name="top"></slot></div>
    </AppButton>

    <div class="accordion-item__bottom" ref="bottomRef">
      <div class="accordion-item__content">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { onUnmounted } from "vue";

import AppButton from "@/elements/AppButton.vue";

export default {
  components: { AppButton },
  props: {
    id: {
      type: Number,
      requred: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["open"],
  setup(props) {
    const bottomRef = ref();
    const itemRef = ref();

    watch(
      () => props.isOpen,
      () => {
        bottomRef.value.style.maxHeight = setMaxHeight(bottomRef.value);
      }
    );

    function setMaxHeight(bottom) {
      return props.isOpen ? bottom.scrollHeight + "px" : null;
    }

    function setMaxWidth() {
      const wrapper = document.querySelector(".wrapper");
      const padLeft = getComputedStyle(wrapper).paddingLeft;
      const padRight = getComputedStyle(wrapper, null).paddingRight;

      const removePx = (el) => {
        return Number(el.slice(0, el.indexOf("px")));
      };

      const width =
        wrapper.clientWidth - (removePx(padLeft) + removePx(padRight));

      itemRef.value.style.maxWidth = width + "px";
    }

    onMounted(() => {
      setMaxWidth()
      bottomRef.value.style.maxHeight = setMaxHeight(bottomRef.value);
      window.addEventListener("resize", setMaxWidth);

     
    });

    onUnmounted(() => {
      window.removeEventListener("resize", setMaxWidth);
    });

    return { bottomRef, itemRef };
  },
};
</script>

<style lang="scss">
.accordion-item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;

  opacity: 0;
  transform: translate3d(0, -10%, 0);
  animation: slide-fade-in 0.2s cubic-bezier(0.32, 0, 0.67, 0) 1 forwards;

  &__top-slot {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-right: 16px;
  }

  &__top {
    background: #f5f5f5;
    border: transparent;
    border-radius: 10px;
    padding: 32px;
    transition: all 0.5s ease-out;
    position: relative;
    justify-content: space-between;
    text-align: left;

    &:after {
      content: "+";
      position: relative;
      color: $accent-1;
      width: 32px;
      height: 32px;
      font-weight: 700;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;
      flex-shrink: 0;
    }
  }

  &__bottom {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    font-weight: 500;

    line-height: 26px;
    color: #6f6b80;
    text-align: left;

    .accordion-item__content {
      padding: 30px;
      overflow: hidden;
    }
  }

  &.opened {
    box-shadow: $shadow-1;

    .accordion-item {
      &__top {
        background: #fff;

        &::after {
          background-color: $accent-1;
          color: #fff;
          content: "—";
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    &__top {
      padding: 16px;

      &::after {
        display: none;
      }

      p {
        font-size: 18px;
        line-height: 22px;
        margin-right: 8px;
      }
    }
  }
}
</style>
