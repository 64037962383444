<template>
  <ul class="links-list">
    <li v-for="l in links" :key="l.id">
      <router-link :to="l.link" @click="$emit('link')">
        <AppIcon v-if="l.icon" :icon="l.icon" />
        <span>{{ l.title }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import AppIcon from "@/elements/AppIcon";

export default {
  emits: ["link"],
  components: { AppIcon },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.links-list {
  list-style: none;
  padding: 0;

  svg.app-icon {
    margin-right: 16px;
    stroke-width: 2px;
    stroke: $accent-1;
  }

  a {
    color: $text;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    font-weight: 700;
    transition: all 0.3s;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0;
    &:hover {
      color: $accent-1;
    }
  }
}
</style>
