<template>
  <div class="n-user-settings">
    <router-view v-slot="{ Component }" v-if="$route.path !== '/user/settings'">
      <component
        :is="Component"
        @changeAvatar="$emit('changeAvatar', $event)"
        @changeBio="$emit('changeBio', $event)"
        @changeEmail="$emit('changeEmail', $event)"
        @changePassword="$emit('changePassword', $event)"
      ></component>
    </router-view>

    <div class="n-user-settings__buttons" v-else>
      <AppBackButton
        :linkMode="true"
        link="/user/panel"
      />

      <router-link
        v-for="v in userSettingsList"
        :key="v.id"
        class="n-user-settings__link"
        :to="v.link"
      >
        <span>{{ v.title }}</span>
        <AppIcon icon="chevron-icon" />
      </router-link>
    </div>
  </div>
</template>

<script>
import AppBackButton from "@/elements/AppBackButton.vue";
import AppIcon from "@/elements/AppIcon";

import { t } from "@/utils/translate";
import { userSettingsList } from "@/app-data/static";

export default {
  components: { AppIcon, AppBackButton },
  emits: ["changeAvatar", "changeBio", "changeEmail", "changePassword"],
  setup() {
    return { t, userSettingsList };
  },
};
</script>

<style lang="scss">
.n-user-settings {
  &__buttons {
    padding: 30px;
  }

  &__link {
    @include button-default;
    text-decoration: none;
    background-color: rgba($text, 0.1);
    color: $text;
    font-size: 18px;
    line-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;

    svg {
      stroke: $text;
    }

    &:hover {
      color: #fff;
      background-color: rgba($text, 1);

      svg {
        stroke: #fff;
      }
    }
  }

  @media (max-width: 480px) {
    &__link {
      font-size: 16px;
    }
  }
}
</style>
