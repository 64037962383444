<template>
  <div class="n-user-content-wrapper">
    <AppTitle mode="h4" class="n-user-content-wrapper__title">
      <template v-slot:title>{{ title }}</template>
    </AppTitle>

    <div class="n-user-content-wrapper__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/elements/AppTitle.vue";

export default {
  components: { AppTitle },
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.n-user-content-wrapper {
  &__title {
    padding: 30px;
    border-bottom: 1px solid rgba($text, 0.1);
  }

  &__content {
    padding: 30px;
    height: 100%;
  }

  .app-back-button {
    margin-bottom: 30px;
    display: none;

    @media (max-width: 992px) {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    &__content {
      padding: 16px;
    }
  }

  @media (max-width: 768px) {
    &__content {
      padding: 8px;
    }
  }
}
</style>
