<template>
  <router-link
    :to="`/course/${element.id}/overview`"
    class="n-course-preview"
   
  >
    <div class="n-course-preview__img">
      <LikeButton
        class="n-course-preview__like"
        :element="element"
        @toggleFavoriteStatus="$emit('toggleFavoriteStatus')"
      />

      <AppImage
        v-if="element.img"
        :image="`https://navigator-backend.geekbase.ru/api/download/avatar/course${element.img}`"
      />
    </div>

    <div
      class="flex n-course-preview__icon-text n-course-preview__coach"
      v-if="element.coach"
    >
      <AppIcon icon="circle-user-icon" />
      <div class="n-course-preview__name flex">
        <span v-if="element.coach.firstname">{{
          element.coach.firstname
        }}</span>
        <span v-if="element.coach.secname">{{ element.coach.secname }}</span>
        <span v-if="element.coach.lastname">{{ element.coach.lastname }}</span>
      </div>

    </div>

    <AppTitle mode="h4" class="n-course-preview__title">
      <template v-slot:title>{{ element.title }}</template>
    </AppTitle>

    <p>{{ element.description }}</p>

    <div class="flex flex__sb n-course-preview__info">
      <div class="flex n-course-preview__icon-text">
        <AppIcon icon="star-icon" />
        <span>{{ element.stars }}</span>
      </div>

      <div class="flex n-course-preview__icon-text">
        <AppIcon icon="book-icon" />
        <span>{{ t("lessons.quantity") }}: {{ element.lessonsQuantity }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { t } from "@/utils/translate";

import AppIcon from "@/elements/AppIcon";
import AppImage from "@/elements/AppImage";
import AppTitle from "@/elements/AppTitle.vue";
import LikeButton from "@/elements/LikeButton.vue";

export default {
  emits: ["toggleFavoriteStatus"],
  components: { AppImage, AppIcon, AppTitle, LikeButton },
  props: {
    element: {
      type: Object,
      required: true,
    },
    inFavorite: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.n-course-preview {
  padding: 20px 20px 30px;
  border: 1px solid rgba($text, 0.1);
  box-shadow: 0 5px 20px rgb($text, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  grid-template-rows: 200px max-content max-content 1fr max-content;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
  color: $text;
  text-decoration: none;

  p {
    align-items: flex-start;
  }

  &__coach {
    margin: 16px 0;

    span {
      margin: 0 4px;
    }
  }

  &__name {
    flex-wrap: wrap;

    svg {
      margin-right: 8px;
    }
  }

  &__title {
    text-align: left;
  }

  &__img {
    width: 100%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    height: 200px;
    background-image: $gradient;
  }

  &__like {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
    transition: all 0.2s;

    svg {
      stroke: $accent-1;
    }

    &:hover {
      background-color: $accent-1;

      svg {
        stroke: #fff;
      }
    }
  }

  &__icon-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;

    svg {
      margin-right: 4px;
    }
  }

  &__info {
    color: $text;

    svg {
      stroke: $accent-1;
    }
  }

  &:hover {
    background-color: #fff;
    box-shadow: 0 10px 30px rgb($text, 0.1);

    .app-image img {
      transform: scale(1.2);
    }
  }

  @media (max-width: 768px) {
    padding: 16px;

    &__title {
      font-size: 20px;
      line-height: 22px;
    }

    .app-title.small h3 {
      margin-bottom: 8px;
    }
  }
}
</style>
