<template>
  <StateWrapper
    :content="salons"
    :loadingStatus="loadingStatus"
    :checkLogin="false"
    class="salon-items"
  >

    <masonry-wall
      :items="getSlicedArray(filteredSalons, itemsPerPage, currentPage)"
      :ssr-columns="1"
      :column-width="300"
      :padding="16"
      :gap="16"
    >
      <template #default="{ item, index }">
        <div style="height: auto">
          <SalonPreview
            :key="index"
            :item="item"
            :isAdmin="isAdmin"
            @goToEdit="goToEdit"
          />
        </div>
      </template>
    </masonry-wall>

    <div class="flex flex__jc-c" v-if="filteredSalons.length">
      <vue-awesome-paginate
        :total-items="filteredSalons.length"
        v-model="currentPage"
        :items-per-page="itemsPerPage"
        :max-pages-shown="maxPagesShown"
      />
    </div>

  </StateWrapper>
</template>

<script>
import { ref, computed } from "vue";
import { usePagination } from "@/composables/usePagination";
import { useUserStore } from "@/store/userStore";
import { usePopupStore } from "@/store/popupStore";

import { useRouter } from "vue-router";
import StateWrapper from "@/components/StateWrapper.vue";
import SalonPreview from "@/components/SalonPreview.vue";


export default {
  components: {
    SalonPreview,
    StateWrapper,
  },
  props: {
    salons: {
      type: Array,
      required: true,
    },
    loadingStatus: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const popupStore = usePopupStore();
    const { showPopup } = popupStore;
    const userStore = useUserStore();

    const { addEditedWebinarData } = userStore;
    const router = useRouter();

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);

    const currentFilters = ref([]);



    const { getSlicedArray } = usePagination();

    const filteredSalons = computed(() => {
      return props.salons;
    });

    function goToEdit(d) {
      router.push({ path: "/user/salon/planning/edit" });
      addEditedWebinarData(d);
    }


    return {
      goToEdit,
      filteredSalons,
      currentFilters,
      currentPage,
      maxPagesShown,
      itemsPerPage,
      getSlicedArray
    };
  },
};
</script>

<style lang="scss">
.salon-items {
  &__items {
    @include custom-grid;
  }
}
</style>
