<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Оповещения</v-tab>
      <v-tab value="2">Получатели</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card v-if="!notificationDialog">
          <v-card-title class="card_ligth_header ma-0 pa-0">
            <v-row  class=" ma-2 pa-2" >
              <v-col cols="12">
                <h3>Оповещения</h3>
              </v-col>
              <v-col cols="12">
                <AppButton
                    class="n-parametrs__opener d-flex flex__ai-c"
                    @click="notificationDialog=true">
                  <span>Добавить</span>
                </AppButton>
              </v-col>

            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pb-10" >
            <v-row>
              <v-col cols="12">
                <Vue3EasyDataTable
                    class="mb-16 pb-16"
                    :headers="notificationHeaders"
                    :items="notifications"
                    alternating
                    show-index
                    :rows-per-page-message="'Записей на страницу'"
                    :rows-per-page = "10"
                    :rows-items="[10,20,50]"
                    :rows-of-page-separator-message	="'из'"
                    buttons-pagination
                    :empty-message="'Данные не добавлены'"
                >

                  <template #item-createDateTime="{createDateTime}">
                    {{ createDateTime.replace('T', ' ')}}
                  </template>
                  <template #item-complete="{complete}">
                    <p v-if="complete">Да</p>
                    <p v-else>Нет</p>
                  </template>

                  <template #item-actions="item">
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="editNotification(item)">
                      <span>Изменить</span>
                    </AppButton>
                    <AppButton
                        class="admin-lib__table_button d-flex flex__ai-c"
                        @click="deleteNotification(item)">
                      <span>Удалить</span>
                    </AppButton>
                  </template>
                </Vue3EasyDataTable>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title class="card_ligth_header">
            <h5>Оповещение</h5>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form
                ref="notificationForm"
            >
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Тема*"
                        v-model="subject"
                        :rules="subjectRules"
                        hint="Укажите тему письма"
                        :counter="1500"
                        required
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-16" style="height: 350px;">
                    <quill-editor
                        style="height: 200px;"
                        v-model:value="body"
                        :options=" {
                        modules: {
                        imageDrop: true,
                        imageResize: true,
                          htmlEditButton: {
                          debug: true,
                          syntax: false,
                          buttonTitle: 'Показать HTML код',
                          msg: 'Отредактируйте HTML  в этом окне,  для сохранения нажмите - сохранить',
                          okText: 'Сохранить',
                          cancelText: 'Отмена',
                          buttonHTML: '&lt;&gt;',
                          prependSelector: 'div#myelement',
                          editorModules: {}

                },
                        imageCompress: {
                         quality: 0.7, // default
                         maxWidth: 1000, // default
                        // maxHeight: 1000, // default
                         imageType: 'image/jpeg', // default
                         debug: true, // default
                         suppressErrorLogging: false, // default
                         insertIntoEditor: undefined, // default
                        }
                        }
                      }"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-label>Отправка только зарегистрированным пользователям:</v-label>
                    <v-container class="px-0" fluid>
                      <v-switch
                          v-model="sendForRegUsers"
                          :label="sendForRegUsers ? 'Да' : 'Нет'"
                      ></v-switch>
                    </v-container>
                  </v-col>

                  <v-col v-if="sendForRegUsers" cols="12">

                    <v-container class="px-0" fluid>
                      <AppButton
                          class="admin-lib__opener d-flex flex__ai-c"
                          @click="recipientPersons = parentList"
                      >
                        <span>Выбрать всех</span>
                      </AppButton>
                    </v-container>
                  </v-col>

                  <v-col v-if="sendForRegUsers" cols="12">
                    <v-autocomplete
                        v-model="recipientPersons"
                        :items="parentList"
                        :custom-filter="recipientFilter"
                        filled
                        chips
                        clerable
                        small-chips
                        closable-chips
                        label="Выбрать получателей из зарегистрированных пользователей"
                        item-title="name"
                        item-value="name"
                        return-object
                        multiple
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="
                          item.raw.firstname +
                          ' ' +
                          item.raw.lastname
                        "
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            :title="
                          item.raw.firstname +
                          ' ' +
                          item.raw.lastname
                        "
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col v-if="!sendForRegUsers" cols="12">
                    <v-autocomplete
                        v-model="recipients"
                        :items="recipientsList"
                        :custom-filter="recipientFilter"
                        filled
                        chips
                        clerable
                        small-chips
                        closable-chips
                        label="Выбрать получателей из списка для оповещения"
                        item-title="name"
                        item-value="name"
                        return-object
                        multiple
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                            v-bind="props"
                            :text="
                          item.raw.firstname +
                          ' ' +
                          item.raw.lastname
                        "
                        ></v-chip>
                      </template>
                      <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            :title="
                          item.raw.firstname +
                          ' ' +
                          item.raw.lastname
                        "
                        ></v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-container class="px-0" fluid>
                      <v-switch
                          v-model="complete"
                          :label="complete ? 'Отправленна в рассылку' : 'Черновик'"
                      ></v-switch>
                    </v-container>
                  </v-col>

                </v-row>
              </v-container>
              <small>*обязательно для заполнения</small>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="card_ligth_actions">
            <v-spacer></v-spacer>
            <AppButton
                class="admin-lib__opener d-flex flex__ai-c"
                @click="closeNotificationForm()"
            >
              <span>Отмена</span>
            </AppButton>
            <AppButton
                class="admin-lib__opener d-flex flex__ai-c"
                @click="saveNotification()"
            >
              <span>Сохранить</span>
            </AppButton>
          </v-card-actions>
        </v-card>

      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Получатели</h3>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="recipientDialog=true">
                <span>Добавить получателя</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  class="mb-16 pb-16"
                  :headers="recipientsHeaders"
                  :items="recipientsList"
                  alternating
                  show-index
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "10"
                  :rows-items="[10,20,50]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-name="{firstname, lastname, secname}">
                  <strong v-if="!!lastname">{{lastname  + " "}}  </strong>
                  <strong v-if="!!firstname">{{firstname + " "}} </strong>
                  <strong v-if="!!secname">{{secname}} </strong>
                </template>

                <template #item-agreement="{agreement}">
                  <p v-if="agreement">Да</p>
                  <p>Нет</p>
                </template>


                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editRecipient(item)">
                    <span>Изменить</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteRecipient(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>

  </div>

  <v-dialog v-model="recipientDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Получатель</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="recipientForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Фамилия*"
                    v-model="lastname"
                    :rules="lastnameRules"
                    hint="Укажите фамилию"
                    :counter="50"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Имя*"
                    v-model="firstname"
                    :rules="firstnameRules"
                    :counter="50"
                    variant="outlined"
                    hint="Укажите имя"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Отчество*"
                    v-model="secname"
                    :rules="secnameRules"
                    variant="outlined"
                    :counter="50"
                    hint="Укажите отчество"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="emailAddress"
                    variant="outlined"
                    label="Email*"
                    hint="Укажите e-mail"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeRecipientForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveRecipient"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>

  <v-dialog v-model="showLoader" persistent >
    <loader-ring></loader-ring>
  </v-dialog>
</template>

<script>
import { customAxios} from "@/service/customAxios";
import {customStorage} from "@/service/customStorage";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminUserPage',

  props: [],
  components:{
    LoaderRing,
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    id:'',
    subject:'',
    subjectRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    body:'',
    complete: false,

    firstname:'',
    lastname:'',
    secname:'',
    unsubscribeUuid:'',
    emailAddress:'',
    agreement:true,

    notifications:[],
    recipients:[],
    recipientsList:[],
    parentList:[],

    recipientPersons:[],

    sendForRegUsers:false,
    notificationDialog: false,
    recipientDialog: false,


    recipientsHeaders: [
      { text: "Имя", value: "name" , sortable: true, width: 100 },
      { text: "Email", value: "emailAddress" , sortable: true, width: 100 },
      { text: "Согласие", value: "agreement" , sortable: true, width: 120 },
      { text: "Действие", value: "actions", width: 130},
    ],
    notificationHeaders: [
      { text: "id", value: "id" , sortable: true},
      { text: "Тема", value: "subject" , sortable: true  },
      { text: "Добавлено", value: "createDateTime" , sortable: true},
      { text: "Завершено", value: "complete" , sortable: true},
      { text: "Действие", value: "actions", width: 280},
    ],

    showLoader: false,
    firstnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    lastnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    secnameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],
    usernameRules: [
      v => (v && v.length <= 50) || 'Значение до должно превышать 50 символов!',
    ],


    page: 1,
    noteCount: 20,
    tab:1,

  }),
  methods: {
    closeNotificationForm(){
      this.notificationDialog = false;
      this.subject='';
      this.body='';
      this.complete = false;
      this.sendForRegUsers = false;
      this.id = '';
      this.recipients = this.recipientsList;
      this.recipientPersons = this.parentList;

    },
    saveNotification(){
      this.$refs.notificationForm.validate().then(responce =>{
        if(responce.valid){
          let notification = {
            subject:capitalizeFirstLetter(this.subject),
            body:capitalizeFirstLetter(this.body),
            complete: this.complete,
            recipients: this.recipients,
            recipientPersons: this.recipientPersons,
            sendForRegUsers: this.sendForRegUsers
          }
          if (this.id) {
            customAxios.putWithAuth('notification/notification/' + this.id, notification)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.notifications, response.data.id)
                    this.notifications.splice(index, 1, response.data)
                    this.closeNotificationForm();

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {
            customAxios.post('notification/notification', notification)
                .then(response => {
                  if (response.status === 201) {
                    this.notifications.push(response.data)
                   this.closeNotificationForm()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },
    recipientFilter(item, queryText, itemText) {
      const textFirstname = itemText.raw.firstname.toLowerCase();
      const textLastname = itemText.raw.lastname.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textFirstname.indexOf(searchText) > -1 ||
          textLastname.indexOf(searchText) > -1
      );
    },
    editNotification(item){
      this.subject=item.subject;
      this.body=item.body;
      this.complete = item.complete;
      this.id = item.id;
      this.recipients = item.recipients;
      this.recipientPersons = item.recipientPersons;
      this.sendForRegUsers = item.sendForRegUsers;
      this.notificationDialog = true;
    },

    closeRecipientForm(){
      this.recipientDialog = false;
      this.lastname = '';
      this.firstname = '';
      this.secname = '';
      this.emailAddress = '';
    },
    saveRecipient(){
      this.$refs.recipientForm.validate().then(responce =>{
        if(responce.valid){
          let recipient = {
            lastname:capitalizeFirstLetter(this.lastname),
            firstname:capitalizeFirstLetter(this.firstname),
            secname:capitalizeFirstLetter(this.secname),
            emailAddress: this.emailAddress
          }
          if (this.id) {
            customAxios.putWithAuth('notification/recipient/' + this.id, recipient)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.recipientsList, response.data.id)
                    this.recipientsList.splice(index, 1, response.data)
                    this.closeRecipientForm();

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {
            customAxios.post('notification/recipient', recipient)
                .then(response => {
                  if (response.status === 201) {
                    this.recipientsList.push(response.data)
                    this.closeRecipientForm()
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })
    },
    editRecipient(item){
      this.lastname=item.lastname;
      this.firstname=item.firstname;
      this.secname = item.secname;
      this.id = item.id;
      this.emailAddress = item.emailAddress;
      this.recipientDialog = true;
    },

    deleteRecipient(item){
      customAxios.delete('notification/recipient/' + item.id)
          .then(response => {
            if (response.status === 202) {
              let index = getIndex(this.recipientsList, response.data.id)
              this.recipientsList.splice(index)
              this.closeRecipientForm();

            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })

    },

    deleteNotification(item){
      customAxios.delete('notification/notification/' + item.id)
          .then(response => {
            if (response.status === 202) {
              let index = getIndex(this.notifications, response.data.id)
              this.notifications.splice(index)
              this.closeRecipientForm();

            } else if (response.status === 207) {
              alert(response.data.message)
              console.log(response)
            } else {
              alert("что то пошло не так")
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })

    },

  },
  computed: {

    jwtHeaders(){
      let jwtTokenConfig = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + customStorage.get("user-token"),
        },
        responseType: 'blob'
      }
      return jwtTokenConfig
    },

  },
  created: function () {
    this.showLoader=true

    function getNotifications() {
      return customAxios.getWithAuth('notification/notification')
    }

    function getRecipients() {
      return customAxios.getWithAuth('notification/recipient')
    }

    function getPersons() {
      return customAxios.getWithAuth('person/parent')
    }

    Promise.all([
      getNotifications(),
      getRecipients(),
      getPersons()

    ])
        .then(results => {
          if(results[0].status===200){
            this.notifications = results[0].data;
          }
          if(results[1].status===200){
            this.recipientsList = results[1].data;
            console.log(this.recipientsList)
          }
          //parentList
          if(results[2].status===200){
            this.parentList = results[2].data;
            console.log(this.parentList)
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")

    });
  }
}

</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
