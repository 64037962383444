// Статичные данные для приложения:
import { t } from "@/utils/translate";




export const announcementsTabs = [
  { id: 1, title: `${t("news")}`, link: "news" },
  { id: 2, title: `${t("events")}`, link: "events" },
];

export const navigation = [
  { title: t("main"), link: "/" },
  { title: t("events.main"), link: "/event" },
  { title: "О кафедре", link: "/history" },
];

export const mobileNavigation = [
  ...navigation,
  { title: t("account"), link: "/user/account" },
  { title: t("userAgree"), link: "/useragree" },
  { title: t("politic"), link: "/politic" },
];


export const userSettingsList = [
  { id: 1, title: t("change.bio"), link: "/user/settings/name" },
  { id: 2, title: t("change.email"), link: "/user/settings/email" },
  { id: 3, title: t("change.password"), link: "/user/settings/password" },
  { id: 4, title: t("change.avatar"), link: "/user/settings/avatar" },
];

export const adminTabs = [
  { id: 1, title: `Пользователи`, link: "users" },
  { id: 2, title: `Конференции`, link: "events" },
  { id: 3, title: `Оповещения`, link: "notifications" },
];



export const salonFilters = [
  {
    id: 0,
    active: true,
    name: "Опубликован",
    value: "1",
    parametrName: "published",
  },
  {
    id: 1,
    active: true,
    name: "Не опубликован",
    value: "0",
    parametrName: "published",
  },
  {
    id: 2,
    active: true,
    name: "Вебинар",
    value: "0",
    parametrName: "salonType",
  },
  {
    id: 3,
    active: true,
    name: "Ссылка на внешний ресурс",
    value: "1",
    parametrName: "salonType",
  },
];

export const publicSalonFilters = [
  {
    id: 2,
    active: true,
    name: "Вебинар",
    value: "0",
    parametrName: "salonType",
  },
  {
    id: 3,
    active: true,
    name: "Ссылка на внешний ресурс",
    value: "1",
    parametrName: "salonType",
  },
];

export const panelUserLinks = [
  { id: 3, title: t("favorites"), link: "/user/favorites", icon: "like-icon" },
  {
    id: 4,
    title: t("settings"),
    link: "/user/settings",
    icon: "settings-icon",
  },

];

export const panelAdminLinks = [
  { id: 1, title: t("admin.panel"), link: "/admin", icon: "calendar-icon" },
];

export const promoSectionPhotos = {
  bigPhotos: [
    "promo-2.jpg"
  ],

  middlePhotos: [

    "promo-1200-2.jpg"
  ],

  smallPhotos: [

    "promo-768-2.jpg"
  ],
};
