<template>
  <div class="app-image">
    <img :src="image" />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.app-image {
  overflow: hidden;
  object-fit: cover;
  display: flex;

  img {
    transition: all 0.3s;
    transform: scale(1);
    width: 100%;
    height: 100%;
  }
}
</style>