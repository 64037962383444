<template>
  <div class="auth-form">
    <AppTitle mode="h3" class="auth-form__title">
      <template v-slot:title>{{ title }}</template>
    </AppTitle>

    <div class="auth-form__top">
      <slot name="top"></slot>
    </div>

    <div class="auth-form__inputs">
      <slot name="inputs"></slot>
    </div>

    <div class="auth-form__buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/elements/AppTitle";

export default {
  components: { AppTitle },
  props: {
    title: { type: String, required: true },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.auth-form {
  max-width: 620px;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;

  &__title {
    margin-top: 16px;
    margin-bottom: 8px;
    text-align: center;
  }

  &__top {
    @include flex-center;
    margin-bottom: 30px;

    button {
      margin-left: 16px;
    }
  }

  &__buttons {
    margin-top: 40px;

    button {
      width: 100%;
    }
  }
}
</style>
