<template>
  <MainTemplate />
</template>

<script>
import MainTemplate from "@/templates/MainTemplate.vue";



export default {
  components: { MainTemplate },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/styles/base.scss";
@import "@/styles/v-transitions.scss";
@import "@/styles/animations.scss";
</style>
