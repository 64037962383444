<template>
  <UserContentWrapper
    :title="t('change.email')"
    @back="$emit('back')"
    class="n-user-set-email"
  >
    <AppBackButton
      :linkMode="true"
      link="/user/settings"
      class="n-user-set-email__back"
    />

    <AppInput
      v-model="ve$.email.$model"
      :label="t('email')"
      id="u-email"
      :class="setValidationStatusClass(ve$.email)"
      @blur="ve$.email.$touch"
      :element="ve$.email"
    />
    {{ email }}
    <AppButton
      @click="$emit('changeEmail', { personEmail: email })"
      :disabled="ve$.$invalid"
    >
      {{ t("save") }}
    </AppButton>
  </UserContentWrapper>
</template>

<script>
import AppInput from "@/elements/AppInput.vue";
import AppButton from "@/elements/AppButton";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";

import { setValidationStatusClass } from "@/utils/validation";

import { t } from "@/utils/translate";
import { ref } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";

export default {
  emits: ["changeEmail"],
  components: { AppInput, AppButton, UserContentWrapper, AppBackButton },
  setup() {
    const email = ref("");

    const eRules = {
      email: { required, min: minLength(6) },
    };

    const ve$ = useVuelidate(eRules, {
      email: email,
    });

    return { email, t, ve$, setValidationStatusClass };
  },
};
</script>

<style lang="scss">
.n-user-set-email {
  button {
    @include button-default;
    margin-top: 30px;
  }
}
</style>
