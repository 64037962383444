<template>
  <div class="auth-page">
    <div class="wrapper">
      <transition name="slide-from-right" mode="out-in">
        <KeepAlive>
          <NLogin
            v-if="$route.params.type === 'login'"
            @change-form="changeForm"
            @login="login"
            class="auth-page__form"
          />

          <NRegister
            v-else-if="$route.params.type === 'registration'"
            @change-form="changeForm"
            @register="register"
            class="auth-page__form"
          />

          <NRestorePassword
            v-else-if="$route.params.type === 'restorePassword'"
            @change-form="changeForm"
            @send-email="sendVerificationEmail"
            class="auth-page__form"
          />

          <AuthCodeVeification
            v-else-if="$route.params.type === 'codeVerification'"
            @change-form="changeForm"
            @approve-code="approveCode"
            class="auth-page__form"
          />

          <NAuthEmailVeification
            v-else-if="$route.params.type === 'codeEmailVerification'"
            @change-form="changeForm"
            @approve-email-code="approveEmailCode"
            class="auth-page__form"
          />

          <NAuthEmailUnsubscribeVeification
            v-else-if="
              $route.params.type === 'codeEmailUnsubscribeVerification'
            "
            @change-form="changeForm"
            @approve-email-unsubscribe-code="approveEmailUnsubscribeCode"
            class="auth-page__form"
          />

          <NUnsubscribeVeification
              v-else-if="
              $route.params.type === 'codeUnsubscribeVerification'
            "
              @change-form="changeForm"
              @approve-unsubscribe-code="approveUnsubscribeCode"
              class="auth-page__form"
          />
        </KeepAlive>
      </transition>
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

import { usePopupStore } from "@/store/popupStore";
import { useUserStore } from "@/store/userStore";

import { useRouter } from "vue-router";
import { customStorage } from "@/service/customStorage";

import NLogin from "@/components/NLogin.vue";
import NRegister from "@/components/NRegister.vue";
import NRestorePassword from "@/components/NRestorePassword.vue";

import AuthCodeVeification from "@/components/AuthCodeVeification.vue";
import NAuthEmailVeification from "@/components/NAuthEmailVeification";
import NAuthEmailUnsubscribeVeification from "@/components/NAuthEmailUnsubscribeVeification";

import { capitalizeFirstLetter } from "@/utils/common";
import { customAxios } from "@/service/customAxios";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import NUnsubscribeVeification from "@/components/NUnsubscribeVeification.vue";
// import { storeToRefs } from "pinia";

export default {
  components: {
    NUnsubscribeVeification,
    NLogin,
    NRegister,
    NRestorePassword,
    AuthCodeVeification,
    NAuthEmailVeification,
    NAuthEmailUnsubscribeVeification,
  },
  setup() {
    const router = useRouter();
    const { showPopup } = usePopupStore();
    const userStore = useUserStore();
    const { setUser, setToken } = userStore;
    const { userToken } = storeToRefs(userStore);

    function changeForm(type) {
      router.push({ path: `/auth/${type}` });
    }

    async function register({ firstName, lastName, email, password }) {
      console.log(firstName, lastName, email, password);
      try {
        const r = await customAxios.post("auth/signup", {
          firstName: capitalizeFirstLetter(firstName),
          lastName: capitalizeFirstLetter(lastName),
          email: email,
          password: password,
        });

        if (r.status === 201) {
          showPopup(
            "Вы успешно зарегистрировались! На электронную почту отправлена ссылка для активации учетной записи!",
            "success"
          );
          changeForm("login");
          return r.data;
        }

        if (r.status === 207) {
          throw new Error(r.data.message);
        }

        showPopup("Вы успешно зарегистрировались", "success");

        changeForm("login");
      } catch (e) {
        showPopup(e.message, "error");
      }
    }

    async function login({ username, password }) {
      try {
        const r = await customAxios.post("auth/signin", {
          username: username,
          password: password,
        });

        if (r.status === 207) {
          showPopup(r.data.message, "error");
        } else {
          setUser(r.data);
          setToken(r.data.token)
          showPopup("Вы успешно вошли в систему", "success");
          router.push({ path: "/user/account" });
        }
      } catch (e) {
        let errorMessage = "Неизвестная ошибка";
        console.log(e);

        if (e.response.status === 401) {
          errorMessage = "Пользователя не существует в системе";
        }

        showPopup(errorMessage, "error");
      }
    }

    async function sendVerificationEmail(email) {
      try {
        await customAxios.post("auth/password_req", {
          userEmailForRequest: email,
          uuid: null,
        });

        customStorage.set("cached-email", email);
        showPopup("Email с кодом отправлен", "success");
        changeForm("codeVerification");
      } catch (e) {
        let errorMessage = "Код не отправлен";

        if (e.response.status === 401) {
          errorMessage = "Email не существует в системе";
        }

        showPopup(errorMessage, "error");
      }
    }

    async function approveCode(code) {
      try {
        await customAxios.post("auth/password_req", {
          userEmailForRequest: null,
          uuid: code,
        });
        showPopup("Email с новым паролем отправлен", "success");
        changeForm("login");
      } catch (e) {
        showPopup(e.message, "error");
      }
    }

    async function approveEmailCode(code) {
      try {
        console.log("codde: " + code)
       const r  = await customAxios.get("auth/email_confirm/" + code);


          console.log(r);
          if (r.status === 200) {
            await setUser(r.data);
            await setToken(r.data.token)
            showPopup("Учетная запись успешно активированна! Сейчас Вы будете перенаправлены на страницу мероприятия, для участия необходимо нажать на кнопку Принять участие ", "success");
            await router.push({ path: "/salon/29" });
          } else if (r.status === 207) {
            showPopup("Не верный код активации!", "error");
          } else {
            console.log(r);
          }

      } catch (e) {
        showPopup(e.message, "error");
      }
    }
    async function approveEmailUnsubscribeCode(code) {
      try {
        await customAxios.get("unsubscribe/" + code).then((response) => {
          console.log(response);
          if (response.status === 202) {
            showPopup("Уведомления успешно отключены!", "success");
            changeForm("login");
          } else if (response.status === 207) {
            showPopup(response.data.message, "error");
          } else {
            console.log(response);
          }
        });
      } catch (e) {
        showPopup(e.message, "error");
      }
    }

    async function approveUnsubscribeCode(code) {
      try {
        await customAxios.get("unsubscribe/notification/" + code).then((response) => {
          console.log(response);
          if (response.status === 202) {
            showPopup("Уведомления успешно отключены!", "success");
            changeForm("login");
          } else if (response.status === 207) {
            showPopup(response.data.message, "error");
          } else {
            console.log(response);
          }
        });
      } catch (e) {
        showPopup(e.message, "error");
      }
    }

    onMounted(() => {
      console.log(userToken.value);
      // if (userToken.value) {
      //   router.push('/user/account')
      // }
    })

    return {
      sendVerificationEmail,
      changeForm,
      t,
      register,
      login,
      approveCode,
      approveEmailCode,
      approveEmailUnsubscribeCode,
      approveUnsubscribeCode
    };
  },
};
</script>

<style lang="scss">
// https://www.headspace.com/login
.auth-page {
  .wrapper {
    @include page;
  }
}
</style>
