<template>
  <router-link :to="link" v-if="linkMode" class="app-back-button">
    <AppIcon class="app-back-button__icon" icon="back-arrow-icon" />
    <span>{{ t("back") }}</span>
  </router-link>

  <AppButton v-else class="app-back-button">
    <AppIcon class="app-back-button__icon" icon="back-arrow-icon" />
    <span>{{ t("back") }}</span>
  </AppButton>
</template>

<script>
import { t } from "@/utils/translate";
import AppButton from "@/elements/AppButton";
import AppIcon from "@/elements/AppIcon";

export default {
  components: { AppButton, AppIcon },
  props: {
    link: {
      type: String,
      required: false,
      default: "/",
    },
    linkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.app-back-button {
  @include link-default;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 30px;

  &__icon {
    margin-right: 8px;
  }
}
</style>
