<template>
  <div class="n-footer">
    <div class="wrapper">
      <nav>
        <ul class="n-footer__nav-list list">
          <li v-for="(v, i) in mobileNavigation" :key="i">
            <router-link :to="v.link">{{ v.title }}</router-link>
          </li>
        </ul>
      </nav>

      <div class="n-footer__contacts">
        <h4>{{ t("our.contacts") }}:</h4>
        <div class="n-footer__contacts-item">
          <a :href="`mailto:${companyInformation.contacts.email}`">
            {{ companyInformation.contacts.email }}
          </a>
        </div>
        <div class="n-footer__contacts-item">
          <a :href="`tel:${companyInformation.contacts.phone}`">
            {{ companyInformation.contacts.phone }}
          </a>
        </div>
        <div class="n-footer__contacts-item">
          <p>
            {{ companyInformation.contacts.address }}
          </p>
        </div>
      </div>

      <div class="n-footer__company">
        <p>© 2023 ФГБОУ ВО ИГМУ Минздрава России</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mobileNavigation } from "@/app-data/static";
import { companyInformation } from "@/app-data/texts";
import { t } from "@/utils/translate";

import AppImage from "@/elements/AppImage";

export default {
  setup() {
    return {
      mobileNavigation,
      companyInformation,
      t,
    };
  },
};
</script>

<style lang="scss">
.n-footer {
  color: #fff;
  background-color: $text;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 40px;
  padding-bottom: 40px;

  &__contacts-item {
    display: flex;
    line-height: 30px;
    margin: 8px 0;
    padding-left: 16px;

    a {
      transition: all 0.2s;
    }

    &:hover {
      a {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content max-content;
    column-gap: 20px;
    row-gap: 60px;
  }

  &__partners {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  &__contacts {
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    h4 {
      margin-bottom: 30px;
    }
  }

  &__fgp {
    width: 200px;
    height: auto;
  }

  &__company {
    grid-column: 1 / -1;
    text-align: center;
  }

  a {
    color: #fff;
    padding: 8px 0;
    border-bottom: 1px solid transparent;
    transition: all 0.2s;
    text-decoration: none;
    line-height: 30px;
  }

  nav {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }

  &__nav-list {
    li + li {
      margin-top: 24px;

      &:hover {
        a {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      grid-template-columns: 1fr;
    }

    &__partners {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    &__contacts {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }

    nav {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
  }
}
</style>
