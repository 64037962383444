<template>
  <div class="user-page">
    <div class="wrapper user-page__wrapper" v-if="user">
      <UserPanel class="user-page__panel user-page__panel--desktop" />

      <router-view v-slot="{ Component }">
        <transition name="slide-from-right" mode="out-in">
          <component
            :is="Component"
            class="user-page__content"
            @changeAvatar="changeAvatar"
            @changeBio="changeBio"
            @changeEmail="changeBio"
            @changePassword="changeBio"
          ></component>
        </transition>
      </router-view>
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { onUnmounted, onMounted } from "vue";
import { setValidationStatusClass } from "@/utils/validation";

import UserPanel from "@/components/UserPanel";
import LoaderRing from "@/elements/LoaderRing";

import { useUserStore } from "@/store/userStore";
import { usePopupStore } from "@/store/popupStore";

import { storeToRefs } from "pinia";
import { customAxios } from "@/service/customAxios";
import { useRouter } from "vue-router";

export default {
  components: { UserPanel, LoaderRing },
  setup() {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const { setUser, removeUser } = userStore;
    const { showPopup } = usePopupStore();
    const router = useRouter();

    function changeModeByActualWidth() {
      if (window.innerWidth <= 992) {
        router.push({ path: "/user/panel" });
      } else {
        router.push({ path: "/user/account" });
      }
    }

    async function getUser() {
      try {
        const r = await customAxios.getWithAuth(`person/i`);
       
        setUser(r.data);
      } catch (e) {
        router.push({path: '/auth/login'})
      }
    }

    async function changeAvatar(avatar) {
      try {
        await customAxios.post(`upload/avatar/user`, avatar);
        await getUser();

        showPopup(t("new.avatar.saved"), "success");
      } catch (e) {
        showPopup(t("something.went.wrong"), "error");
      }
    }

    async function changeBio({ personEmail, lastname, firstname, password }) {
      try {
        const options = {
          personEmail: personEmail ? personEmail : user.value.personEmail,
          lastname: lastname ? lastname : user.value.lastname,
          firstname: firstname ? firstname : user.value.firstname,
          id: user.value.id,
        };

        if (password) {
          options.password = password;
        }

        await customAxios.put(`person/i`, options);

        if (!personEmail || !password) {
          showPopup(t("personal.data.is.updated"), "success");
        }

        if (personEmail || password) {
          showPopup('Требуется заново войти в аккаунт', "success");
          removeUser();
        } else {
          getUser();
        }
      } catch (e) {
        console.log(e);
        showPopup(t("something.went.wrong"), "error");
      }
    }

    onMounted(() => {
      getUser();
      window.addEventListener("resize", changeModeByActualWidth);
    });

    onUnmounted(() =>
      window.removeEventListener("resize", changeModeByActualWidth)
    );

    return {
      t,
      setValidationStatusClass,
      user,
      changeAvatar,
      changeBio,
    };
  },
};
</script>

<style lang="scss">
.user-page {
  @include page;
  position: relative;

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content {
    flex-grow: 1;
    background-color: #fff;
    border-radius: 16px;
  }

  &__panel {
    margin-right: 30px;
    align-self: flex-start;
  }

  &__wrapper {
    display: flex;
  }

  &__panel--mobile {
    display: none;
  }

  @media (max-width: 992px) {
    &__wrapper {
      display: block;
    }

    &__panel {
      margin-right: 0;
    }

    &__panel--desktop {
      display: none;
    }

    &__panel--mobile {
      display: block;
    }
  }
}
</style>
