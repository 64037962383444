<template>
  <div class="p-home">
    <NPromo ref="promoRef" class="p-home__promo" />
    <section class="wrapper">
      <SlidesWithText
        ref="AnimationedTextRef"
      />
    </section>

    <section class="wrapper">
      <div class="app-list">
        <AppTitle mode="h3" class="app-list__title">
          <template v-slot:title>Добро пожаловать на страницу кафедры</template>
        </AppTitle>
        <p>На данной странице можно ознакомиться с историей и конференциями, проводимыми кафедрой общественного здоровья и здравоохранения ФГБОУ ВО ИГМУ Минздрава России</p>

      </div>
    </section>


  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { ref } from "vue";
import { useObserver } from "@/composables/useObserver";

import NPromo from "@/elements/NPromo.vue";
import SlidesWithText from "@/elements/SlidesWithText.vue";

import {
  companyInformation,
} from "@/app-data/texts";
import AppIcon from "@/elements/AppIcon.vue";
import AppTitle from "@/elements/AppTitle.vue";


export default {
  components: {
    AppTitle,
    NPromo,
    SlidesWithText,


},

  setup() {
    const promoRef = ref({});
    const AnimationedTextRef = ref({});
    const listRef = ref({});
    const titlesRef = ref([]);
    const benefitsRef = ref();

    function setTitleRef(el) {
      titlesRef.value.push(el);
    }

    useObserver(promoRef, (el) => {
      el.classList.add("animated");
    });





    useObserver(
      titlesRef,
      (el) => {
        el.classList.add("animated");
      },
      { threshold: 0.8 }
    );



    return {
      benefitsRef,
      companyInformation,
      t,
      promoRef,
      AnimationedTextRef,
      listRef,
      setTitleRef,
    };
  },
};
</script>

<style lang="scss">
.p-home {
  padding-bottom: 40px;

  &__promo {
    margin-bottom: 100px;
  }

  &__title {
    align-items: center;
    margin-bottom: 60px;

    .app-title__title {
      opacity: 0;
      transform: translate3d(20%, 0, 0);
    }

    .app-title__top {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 768px) {
    &__promo {
      flex-direction: column;
      margin-bottom: 20px;
    }

    section {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

.animated.p-home__title {
  .app-title__top,
  .app-title__title {
    animation: slide-fade-in 0.3s ease-in 0s 1 forwards;
  }

  .app-title__title {
    animation-delay: 0.2s;
  }
}
</style>
