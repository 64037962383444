<template>
  <div class="document-wrapper wrapper">
    <section class="document-wrapper__content">
      <AppTitle mode="h3">
        <template v-slot:title>{{ title }}</template>
      </AppTitle>
      <slot></slot>
    </section>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

import AppTitle from "@/elements/AppTitle.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    AppTitle,
  },
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.document-wrapper {
  @include page;
  background-color: #fff;

  a {
    word-break: break-all;
  }

  li {
    text-align: left;
    margin: 16px 0;
  }

  h4 {
    margin: 40px 0;
  }

  ul {
    margin-left: 8px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
}
</style>
