<template>
  <div class="meta-data">
    <div v-if="data.date" class="flex flex__ai-c meta-data__info-wrapper">
      <AppIcon icon="calendar-icon" />
      <span>
        {{ formatDate(data.date) }}
      </span>
    </div>


  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";

import {getStringDateTime} from "@/utils/common";

export default {
  components: { AppIcon },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    function formatDate(dateString) {
      let formatter = new Intl.DateTimeFormat("ru", {
        weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour:"numeric",
        minute:"numeric"
      });

      return capitalizeFirstLetter(formatter.format(Date.parse(dateString)));
    }

    function capitalizeFirstLetter(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return { getStringDateTime, formatDate };
  },
};
</script>

<style lang="scss">
.meta-data {
  &__info-wrapper {
    color: $accent-1;
    font-weight: 700;
    line-height: 24px;

    svg {
      stroke: rgba($accent-1, 1);
      margin-right: 8px;
    }
  }

  &__info-wrapper + .meta-data__info-wrapper {
    margin-top: 16px;
  }
}
</style>
