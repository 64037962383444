<template>
  <div class="n-avatar">
    <AppImage v-if="image" :image="`https://navigator-backend.geekbase.ru/api/download/avatar/user/${image}`" />
    <div v-else class="n-avatar__pseudo-avatar">
      <AppIcon icon="camera-icon" />
    </div>
  </div>
</template>

<script>
import AppImage from "@/elements/AppImage.vue";
import AppIcon from "./AppIcon.vue";

export default {
  components: { AppImage, AppIcon },
  props: {
    image: {
      type: String || null,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.n-avatar {
  background-color: $accent-1;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  @include flex-center;

  &__pseudo-avatar {
    background-color: $accent-1;
    @include flex-center;

    svg {
      stroke: #fff;
    }
  }
}
</style>
