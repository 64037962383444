<template>
  <div class="admin-page">
    <PageTitle :title="t('admin.panel')" />

    <div class="wrapper admin-page__wrapper">
      <NTabs
          :tabs="adminTabs"
          :menuIsShowed="menuIsShowed"
          startUrl="/admin"
          @toggleMenu="menuIsShowed = !menuIsShowed"
          @closeMenu="menuIsShowed = false"
      />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LoaderRing from "@/elements/LoaderRing.vue";
import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { adminTabs } from "@/app-data/static";

import PageTitle from "@/elements/PageTitle.vue";
import NTabs from "@/components/NTabs.vue";

import { ref } from "vue";
export default {
  setup() {
    const userStore = useUserStore();
    const { user, isAdmin } = storeToRefs(userStore);
    const menuIsShowed = ref(false);

    return {
      menuIsShowed,
      adminTabs,
      user,
      isAdmin,
      t,
    };
  },
  components: { PageTitle, NTabs },
};
</script>

<style lang="scss">
.admin-page {
  position: relative;
  .wrapper {
    @include page;
  }

  &__wrapper {
    background-color: #fff;
  }
}
</style>
