export const theme = {
    namespaced: true,
    state: {
        dark: false
    },
    mutations: {
        SET_DARK(state, dark) {
            state.dark = dark
        }
    },
    actions: {
        setDark({commit}, dark) {
            commit("SET_DARK", dark)
        }
    }
}