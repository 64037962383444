<template>

    <div class="parents-salon">
      <PageTitle :title="'История кафедры'" />

      <div class="wrapper">
        <div class="n-program-overview__content">
          <p>Самостоятельную жизнь кафедре социальной гигиены дала кафедра гигиены на медицинском факультете Иркутского университета, которая возникла в ноябре 1921 г.</p>
          <p>На заседании медицинского факультета 3 ноября 1921 г. профессор Николай Михайлович Анастасиев, приехавший в октябре из Омска, объявил о начале курса и вступительной лекции 10 ноября в анатомическом корпусе на тему "О содержании и методах социальной гигиены и социальной медицины".</p>
          <p>Кафедра общественного здоровья и здравоохранения была организована в 1924 году и называлась тогда кафедрой социальной гигиены. Ее основателем был профессор Алексей Андреевич Корчагин, талантливый организатор здравоохранения, возглавлявший Иркутский губернский отдел здравоохранения.</p>
          <p>А.А. Корчагин долгое время был заместителем декана медицинского факультета университета, в течение трех лет был членом Иркутского Губисполкома и четыре года - депутатом Иркутского горсовета. Под руково-дством А.А. Корчагина в 1924 году был организован Первый съезд врачей Восточной Сибири. Этот период явился начальным этапом деятельности медицинского факультета университета по совместной работе с практическим здравоохранением Восточно-Сибирского региона, важным шагом на пути установления связи практических врачей и работников науки. В 1926 году А.А. Корчагин успешно защитил докторскую диссертацию на тему "Труд, быт, состояние физического развития и здоровья Иркутских рабочих". Он возглавлял кафедру до 1937 года.</p>
          <p>С 1948 по 1970 годы кафедру возглавляла к.м.н., доцент Татьяна Александровна Ларионова, которая была организатором санитарно-гигиенического факультета Иркутского государственного медицинского института и его первым деканом. В 1942 году Т. А. Ларионова была назначена главным врачом факультетских клиник ИГМИ и до конца войны достойно руководила ими.</p>
          <p>В послевоенный период на кафедру пришли новые сотрудники: А.И. Малоземова, С.А. Пасшак, В.П. Пшулковская, Л.А. Бордовская, В.И. Акимова.<br /> В 60-е годы на кафедру приходят работать из практического здравоохранения Н.П. Евсеева (1963 г.), И.М. Комова (1967 г.); после окончания ИГМИ - С.П. Фирсова (1965 г.), Г.В. Поддубная (1968 г.), которые изучали историю зарубежного и отечественного здравоохранения, проблемы охраны материнства и детства, диспансеризации населения, здоровья рабочих промышленных предприятий Иркутской области и др.</p>
          <p>С 1963 года сначала ассистентом, а затем доцентом кафедры работала Нина Павловна Евсеева, с 1970 по 1983 год она возглавляла кафедру. Нина Павловна в 1973 году защитила докторскую диссертацию "Профилактическое направление в оториноларингологии, его перспективы и экономическая эффективность" и в 1974 году была утверждена в ученом звании профессора. Она  автор 125 научных работ (5 монографий), особое место в ряду которых занимают книги "Кому доверить факел Гиппократа" (1 и 2-е издание), написанные в соавторстве с профессором А.Г. Шантуровым.</p>
          <p>В 70-80х годах на кафедре изучались такие проблемы, как заболеваемость подростков, состояние смертности трудоспособного населения г. Иркутска, организация скорой и неотложной медицинской помощи, организация стационарной хирургической помощи в сельском районе, участковой терапевтической помощи населению, заболеваемость и психическое здоровье рабочих крупных промышленных предприятий и др. Этими проблемами занимались новые сотрудники кафедры этих лет - Т. П. Хлыстова (1971 г.), Е. В. Ершов (1976 г.), И.А. Вельм (1976 г), Л.Н. Беломестнова (1977 г.), М.Н. Бараховский (1978 г.), Г.М. Гайдаров (1979 г.), Л.С. Козлова (1979 г.), Л.Я. Титова (1981 г.), Т.И. Алексеевская (1982 г.), Н.В. Короткина (1982 г.), Ю.Э. Крюков (1982 г.), С.Н.Смирнов (1988 г.).</p>
          <p>С 1983 по 1997 год кафедрой заведовала кандидат медицинских наук, доцент, отличник здравоохранения РСФСР Ирина Михайловна Комова. Под ее руководством разрабатывались и внедрялись новые формы и методы учебно-методической работы, развивалась научно-исследовательская деятельность сотрудников и студентов, проводилась совместная работа с органами и учреждениями практического здравоохранения.</p>
          <p>С 1965 по 2002 гг. на кафедре работала Светлана Петровна Фирсова, которая прошла все ступени творческого роста  от старшего лаборанта, аспиранта, ассистента, старшего преподавателя, доцента до профессора. Являлась заместителем председателя Иркутского бюро секции "Экология и здоровье" РЭА, заместителем председателя диссертационного ученого совета по гигиене ИГМУ, заместителем председателя координационного совета по совместной работе с органами практического здравоохранения.</p>
          <p>С 1997 года кафедрой руководит доктор медицинских наук, профессор, Заслуженный врач Российской Федерации, Председатель Иркутского отделения "Российского общества врачей-организаторов здравоохранения" Гайдар Мамедович Гайдаров.</p>
          <p>В 2000 году кафедра социальной медицины, экономики и управления здравоохранением переименована в кафедру общественного здоровья и здравоохранения.</p>
          <p>В настоящее время на кафедре работают профессора - Т.И. Алексеевская, Н.Ю. Алексеева, Н.С. Апханова ; кандидаты медицинских наук, доценты  С.В. Макаров, Е.В. Душина, А.В. Гашенко, И.В. Маевская, Т.В. Колесникова, а также ассистент - О.Ю. Софронов.&nbsp; Старшим лаборантом кафедры работает Людмила Анатольевна Тупченко.</p>
          <p>Коллектив кафедры обеспечивает преподавание на лечебном, медико-профилактическом, педиатрическом и стоматологическом факультетах и на факультете медицинской биохимии по разделам: история медицины, медицинская информатика, общественное здоровье и здравоохранение, медицинское страхование, организация работы Управления (отделений) Роспотребнадзора, экономика здравоохранения, порядки и стандарты медицинской помощи и другие.</p>
          <p>Учебно-методическая работа кафедры проводится с учетом основных современных направлений педагогики высшей школы. На кафедре разработана и внедрена в учебно-методический процесс интеграционная модель взаимодействия кафедры с Клиниками ГОУ ВПО ИГМУ Минздравсоцразвития России "Учебно-научно-производственный комплекс". Данная форма совместной работы позволяет в прикладном аспекте преподавать на до- и по-следипломном уровнях новые организационно-экономические технологии в управлении ЛПУ, выполнять производственную практику на старших курсах факультетов, проводить научные исследования, разрабатывать и внедрять управленческие новации сотрудниками кафедры и университетских клиник, давать оценку эффективности внедрения результатов научных исследований.</p>
          <p>Внедрение новых разделов и методов преподавания дисциплины послужило основанием для издания значительного количества учебно-методических пособий для студентов, врачей-интернов, клинических ординаторов кафедры.</p>
          <p>Кафедра во взаимосвязи и совместной работе с органами управления здравоохранением занимает одно из основных мест в формировании теоретического обоснования политики государства в области охраны здоровья населения на региональном и муниципальном уровне.</p>
          <p>Вклад кафедры в практическое здравоохранение определяется участием профессорско-преподавательского состава в работе органов исполнительной и законодательной власти, коллегии и различных комиссий департамента здравоохранения Иркутской области, Правления территориального фонда ОМС, Правлении и рабочих группах регионального отделения Российского общества врачей-организаторов здравоохранения; в проведении "круглых столов" по актуальным проблемам здравоохранения, ежегодных совместных научно-практических конференций, сертификационных циклов для руководителей органов и учреждений здравоохранения, в издании научных сборников, разработке и внедрении методических материалов, информационных писем и др.</p>
          <p>Кафедра активно взаимодействует с регионами в подготовке руководящих кадров здравоохранения для Республики Бурятия, Тувинской республики, Усть-Ордынского Бурятского автономного округа, республики Саха (Якутия).</p>
          <p>Многогранно организационно-методическое, научное и педагогическое сотрудничество нашей кафедры с ГУ Национальный НИИ общественного здоровья РАМН, министерством здравоохранения Иркутской области, Территориальным фондом обязательного медицинского страхования граждан Иркутской области, страховыми медицинскими организациями, а также, с профильными кафедрами медицинских ВУЗов Российской Федерации, лечебно-профилактическими учреждениями Иркутской области, Республики Бурятия и других регионов, медицинскими ассоциациями и обществами.</p>

        </div>
      </div>
    </div>

</template>

<script>
import SalonItems from "@/components/SalonItems.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { customAxios } from "@/service/customAxios";
import { t } from "@/utils/translate";
import { onMounted, ref } from "vue";
import { publicSalonFilters } from "@/app-data/static";
import StateWrapper from "@/components/StateWrapper.vue";

export default {
  setup() {

    return { t };
  },
  components: { PageTitle },
};
</script>

<style lang="scss">
.parents-salon {
  .wrapper {
    @include page;
  }
}
</style>
