<template>
  <div class="webinar-preview" :class="{ public: !isAdmin }">

    <div class="webinar-preview__content">
      <AppTitle mode="h4">
        <template v-slot:title>{{ item.eventName }}</template>
      </AppTitle>


      <div v-html="item.eventAnnonce"></div>

      <SalonMetaData :data="{ date: item.eventDate }" />

    </div>

    <div class="webinar-preview__bottom">
      <router-link :to="`/event/${item.id}`">Подробнее</router-link>
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

import MetaData from "./MetaData.vue";
import AppTitle from "@/elements/AppTitle.vue";
import SalonMetaData from "@/components/SalonMetaData.vue";

export default {
  emits: ["go-to-edit", "startWebinar"],
  components: {SalonMetaData, AppTitle},
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {


    return {

      t,
    };
  },
};
</script>

<style lang="scss">
.webinar-preview {
  position: relative;
  border-radius: 8px;
  box-shadow: $shadow-2;

  &__chips {
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 700;
  }

  &__chip {
    border: 2px solid rgba($text, 0.1);
    padding: 8px;
    border-radius: 16px;
    margin: 8px;
    text-transform: uppercase;

    & + .webinar-preview__chip {
      margin-left: 16px;
    }
  }

  .app-title {
    justify-content: flex-start;
    border-bottom: 1px solid rgba($text, 0.1);
    padding-bottom: 20px;
  }

  &__content {
    height: 100%;
    padding: 16px;
    padding-top: 40px;
    display: grid;
    grid-gap: 40px;
    grid-template-rows: max-content max-content 1fr max-content min-content;
  }

  &.public .webinar-preview__content {
    grid-template-rows: max-content max-content 1fr max-content;
  }

  &__bottom {
    padding: 16px;

    a,
    button {
      text-decoration: none;
      color: #fff;
      background-color: $accent-1;
      padding: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      font-size: 20px;
      line-height: 30px;
      transition: all 0.2s;

      &:hover {
        background-color: rgba($accent-1, 0.8);
      }

      &:active {
        transform: scale(1.01);
      }
    }

    a,
    button {
      &:nth-child(2n) {
        margin-top: 16px;
        background-color: $accent-2;

        &:hover {
          background-color: rgba($accent-2, 0.8);
        }
      }
    }
  }

  &__admin {
    border-top: 1px solid rgba($text, 0.1);
    padding-top: 20px;
  }
}
</style>
