<template>
  <WaveSection class="login-please">
    <div>
      <AppTitle mode="h3">
        <template v-slot:top>{{ t("access.denied") }}</template>
        <template v-slot:title>
          {{ t("page.available.only.authorized.users") }}
        </template>
      </AppTitle>

      <router-link to="/auth/login" class="login-please__link">
        <span>{{ t("to.auth.page") }}</span>
        <AppIcon icon="arrow-icon" />
      </router-link>
    </div>
  </WaveSection>
</template>

<script>
import AppTitle from "@/elements/AppTitle.vue";
import WaveSection from "./WaveSection.vue";
import AppIcon from "./AppIcon.vue";

import { t } from "@/utils/translate";

export default {
  setup() {
    return { t };
  },
  components: { AppTitle, WaveSection, AppIcon },
};
</script>

<style lang="scss">
.login-please {
  position: relative;
  text-align: center;

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    fill: #fff;
  }

  .app-title {
    justify-content: center;
    margin-bottom: 30px;
  }

  .wrapper {
    position: relative;
    z-index: 1;
    @include page;
  }

  &__link {
    @include button-default;
    @include flex-center;
    text-decoration: none;

    svg {
      stroke: #fff;
    }
  }

  @media (max-width: 767px) {
    &__link {
      line-height: 50px;
    }
  }
}
</style>
