<template>
  <div class="app-switch" :class="{ chosed: chosed }">
    <AppButton @click="$emit('switch')" />

    <span v-if="chosed">{{ okLabel }}</span>
    <span v-else>{{ nokLabel }}</span>
  </div>
</template>

<script>
import AppButton from "./AppButton.vue";

export default {
  emits: ["switch"],
  props: {
    nokLabel: {
      type: String,
      required: true,
    },
    okLabel: {
      type: String,
      required: true,
    },
    chosed: {
      type: Boolean,
      required: true,
    },
  },
  components: { AppButton },
};
</script>

<style lang="scss">
.app-switch {
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
  }

  button {
    margin-right: 8px;
    width: 100px;
    height: 50px;
    background-color: rgba($text, 0.2);
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: rgba($text, 1);
      transform: translate(0, 0);
      transition: all 0.2s;
    }
  }

  &.chosed {
    span {
      color: $accent-1;
    }

    button {
      background-color: rgba($accent-1, 0.2);

      &::after {
        background-color: $accent-1;
        transform: translate(100%, 0);
      }
    }
  }
}
</style>
