<template>
  <AppButton class="not-accented-button">
    <slot></slot>
  </AppButton>
</template>

<script>
import AppButton from "./AppButton.vue";

export default {
  setup() {
    return {};
  },
  components: { AppButton },
};
</script>

<style lang="scss">
.not-accented-button {
  background-color: rgba($text, 0.1);
  color: $text;
  border-radius: 16px;

  svg {
    transition: all 0.2s;
  }

  &:hover {
    color: #fff;
    background-color: rgba($text, 0.5);

    svg {
      stroke: #fff;
    }
  }

  &:active {
    color: #fff;
    background-color: rgba($text, 1);

    svg {
      stroke: #fff;
    }
  }
}
</style>
