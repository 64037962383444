<template>
  <div class="state-wrapper" v-if="(checkLogin && userToken) || !checkLogin">
    <div class="wrapper" v-if="loadingStatus === 'loading'">
      <LoaderRing />
    </div>

    <div v-if="content && content.length">
      <slot></slot>
    </div>

    <div
      class="wrapper"
      v-if="content && !content.length && loadingStatus !== 'loading'"
    >
      <div class="state-wrapper__text">
        <AppTitle mode="h4">
          <template v-slot:title>{{ t("nothing.found") }}</template>
        </AppTitle>
      </div>
    </div>

    <div v-if="loadingStatus === 'error'" class="wrapper">
      <div class="state-wrapper__text">
        <AppTitle mode="h4">
          <template v-slot:title>
            Произошла ошибка, попробуйте перезагрузить страницу
          </template>
        </AppTitle>
      </div>
    </div>
  </div>

  <LoginPlease v-else />
</template>

<script>
import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import LoginPlease from "@/elements/LoginPlease.vue";

import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

export default {
  props: {
    content: {
      type: Array,
      required: false,
    },
    loadingStatus: {
      type: String,
      required: true,
    },
    checkLogin: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();
    const { userToken } = storeToRefs(userStore);

    return { t, userToken };
  },
  components: { LoaderRing, AppTitle, LoginPlease },
};
</script>

<style lang="scss">
.state-wrapper {
  &__text {
    @include page;
    display: flex;
    justify-content: center;
  }
}
</style>
