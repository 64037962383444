<template>
  <WaveSection class="page-title">
    <AppTitle mode="h2" class="page-title__title">
      <template v-slot:title>{{ title }} </template>
    </AppTitle>
  </WaveSection>
</template>

<script>
import AppTitle from "@/elements/AppTitle";
import AppImage from "@/elements/AppImage.vue";
import WaveSection from "./WaveSection.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: { AppTitle, WaveSection },
  setup() {},
};
</script>

<style lang="scss">
.page-title {

  .wrapper {
    position: relative;
    min-height: 100%;
    flex-grow: 1;
    @include flex-center;
  }

  &__title {
    position: relative;
    z-index: 1;
  }

  &__bg-item {
    position: absolute;

    &--1 {
      right: 10%;
      height: 50%;
      top: 40%;
      animation: slide-down 2s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite both;
    }

    &--2 {
      left: 10%;
      top: 10%;
      height: 80%;
      animation: scaleDown 3s cubic-bezier(0.12, 0, 0.39, 0) 0s infinite both;
    }
  }

  @media (max-width: 480px) {
    &__bg-item {
      &--1,
      &--2 {
        height: 20%;
      }
    }
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
