<template>
  <button type="button" class="app-button">
    <slot></slot>
  </button>
</template>

<style lang="scss">
.app-button {
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;
  @include flex-center;
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  padding: 0 16px;
}
</style>
