<template>
  <div class="app-input" :class="{ filled: modelValue }">
    <div class="app-input__input flex">
      <input
        :type="inputType"
        :id="id"
        @input="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        @blur="$emit('blur')"
      />
      <label v-if="label" :for="id">{{ label }}</label>
    </div>

    <div
      v-if="element && element.$dirty && !element.$model"
      class="app-input__error"
    >
      {{ t("please.enter.chars") }}
    </div>
    <div
      v-if="
        element &&
        element.$dirty &&
        element.$path === 'email' &&
        element.$model &&
        element.$errors.length
      "
      class="app-input__error"
    >
      {{ t("please.enter.email") }}
    </div>

    <div
      v-if="
        element &&
        element.min &&
        element.$dirty &&
        element.min.$params.min > modelValue.length &&
        modelValue.length > 0
      "
      class="app-input__error"
    >
      {{ t("please.enter.more.chars") }} {{ element.min.$params.min }}
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    error: {
      type: Object,
      required: false,
    },
    element: {
      type: Object,
      required: false,
    },
   inputType: {
      type: String,
      required: false,
      default: 'text'
    }
  },

  emits: ["update:modelValue", "blur"],
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.app-input {
  &__input {
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;

    input {
      border-radius: 8px;
      width: 100%;
      padding: 24px 16px 8px;
      background-color: #ffffff;
      font-size: 18px;
      line-height: 20px;
      box-shadow: inset 0 0 0 1px #dad7d5;
      outline: none;
    }

    label {
      font-size: 18px;
      line-height: 20px;
      position: absolute;
      bottom: 50%;
      left: 16px;
      transform: translate3d(0, 50%, 0) scale(1);
      transition: all 0.2s;
      pointer-events: none;
      transform-origin: left top;
    }

    input:focus {
      box-shadow: inset 0 0 0 2px $accent-1;

      & + label {
        color: rgb(90, 88, 87);
        transform: translate3d(0, -10%, 0) scale(0.7);
      }
    }
  }

  &.error .app-input__input {
    input {
      box-shadow: inset 0 0 0 2px $error;
    }
    label {
      color: $error;
    }
  }

  &.correct .app-input__input {
    input {
      box-shadow: inset 0 0 0 2px #2ebd59;
    }
  }

  &.filled .app-input__input {
    label {
      color: rgb(90, 88, 87);
      transform: translate3d(0, -10%, 0) scale(0.7);
    }
  }

  &__error {
    padding: 16px 0;
    color: $error;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
  }

  & + .app-input {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    label {
      font-size: 14px;
    }

    input {
      font-size: 16px;
    }
  }
}
</style>
