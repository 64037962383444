<template>
  <StateWrapper
      :content="currentSalon.eventReports"
      :loadingStatus="loadingStatus"
      :checkLogin="false"
  >
    <div class="webinar-layout" v-if="currentSalon">
      <div class="wrapper">
        <AppBackButton :linkMode="true" to="/event"></AppBackButton>
      </div>
      <PageTitle v-if="currentSalon.eventName" :title="currentSalon.eventName" />


      <div class="wrapper webinar-layout__wrapper">
        <transition name="slide-from-right" mode="out-in">
          <div class="n-program-overview">
            <div v-if="currentSalon">
              <AppTitle mode="h3">
                <template v-slot:title>{{ t("event.about") }}</template>
              </AppTitle>
              <div class="n-program-overview__content">
                <div class="salon-data" v-html=" currentSalon.eventDescription "></div>

                <div v-if="currentSalon.attachmentFiles">

                  <AppTitle mode="h3">
                    <template v-slot:title>Файлы конференции</template>
                  </AppTitle>

                  <div v-if="currentSalon.attachmentFiles && currentSalon.attachmentFiles.length">
                    <SalonFilePreview
                        v-for="(e, idx) in currentSalon.attachmentFiles"
                        :key="idx"
                        :attachmentFile="e"
                    />
                  </div>

                </div>

                <div v-if="currentSalon.eventReports">

                  <AppTitle mode="h3">
                    <template v-slot:title>{{ t("event.reports") }}</template>
                  </AppTitle>

                  <div class="help-page__results">
                    <div class="help-page__items">
                      <AccordionItem
                          v-for="(v, idx) in currentSalon.eventReports"
                          :key="v.id"
                          :isOpen="currentId === v.id"
                          @open="setCurrentId"
                          :style="{ 'animation-delay': `0.${idx}s` }"
                          :id="v.id"
                      >
                        <template v-slot:top>{{ v.reportName }}</template>
                        <template v-slot:bottom>
                          <div v-html="v.reportDescription"></div>
                          <div v-if="v.videoFiles && v.videoFiles.length">
                            <SalonVideoPreview
                                v-for="(e, idx) in v.videoFiles"
                                :key="idx"
                                :videoFile="e"
                                @selectVideo="selectVideo"
                            />
                          </div>
                          <SalonMetaData :data="{ date: v.reportDate }" />
                        </template>
                      </AccordionItem>
                    </div>



                    <div v-if="currentSalon.videoFiles && currentSalon.videoFiles.length">
                      <SalonVideoPreview
                          v-for="(e, idx) in currentSalon.videoFiles"
                          :key="idx"
                          :videoFile="e"
                          @selectVideo="selectVideo"
                      />
                    </div>



                    <v-divider class="mt-2 mb-2"></v-divider>


                    <div v-if="!currentSalon.needReg && !currentSalon.joined">
                      <AppButton @click="joinToSalon" class="login-please__link ma-6">
                        <span>В избранное</span>
                      </AppButton>
                    </div>

                    <div v-if="!currentSalon.needReg && currentSalon.joined">
                      <AppButton @click="leaveSalon" class="login-please__link ma-6">
                        <span>Убрать из избранного</span>
                      </AppButton>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <LoaderRing v-else />

          </div>


        </transition>

      </div>

      <NVideoModal
          v-if="showVideo"
          :video="video"
          @closeModal="closeVideoModal"
      />


    </div>
  </StateWrapper>

</template>

<script>

import { t } from "@/utils/translate";
import { onMounted } from "vue";
import { ref } from "vue";
import { customAxios } from "@/service/customAxios";
import { useRoute } from "vue-router";
import PageTitle from "@/elements/PageTitle.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import AppTitle from "@/elements/AppTitle.vue";
import LoginPleaseSalon from "@/elements/LoginPleaseSalon.vue";
import AppButton from "@/elements/AppButton.vue";
import NVideoModal from "@/components/NVideoModal.vue";
import LessonPreview from "@/components/LessonPreview.vue";
import SalonVideoPreview from "@/components/SalonVideoPreview.vue";
import AccordionItem from "@/components/AccordionItem.vue";
import {setCurrentIdValue} from "@/utils/common";
import SalonMetaData from "@/components/SalonMetaData.vue";
import StateWrapper from "@/components/StateWrapper.vue";
import SalonFilePreview from "@/components/SalonFilePreview.vue";

export default {
  components: {
    SalonFilePreview,
    StateWrapper,
    SalonMetaData,
    AccordionItem,
    SalonVideoPreview, NVideoModal, AppButton, AppTitle, LoaderRing, AppBackButton, PageTitle },
  setup() {
    const loadingStatus = ref("loading");
    const currentId = ref();
    const currentSalon = ref("");
    const menuIsShowed = ref(false);
    const route = useRoute();
    const isAdmin = ref(false);
    const video = ref('');
    const showVideo = ref(false);
    function closeVideoModal() {
      showVideo.value = false;
      video.value = null;
    }
    function selectVideo(v) {
      console.log("selectVideo", v);
      video.value = v;
      showVideo.value = true;
    }
    function setCurrentId(value) {
      currentId.value = setCurrentIdValue(value, currentId.value);
    }
    async function getSalonData() {
      try {
        const r = await customAxios.getWithAuth(
            `event/public/${route.params.id}`
        );

        currentSalon.value = r.data;
      } catch (e) {
        console.log(e);
      }
    }
    async function joinToSalon() {
      console.log("join clicked")
      try {
        const r = await customAxios.getWithAuth(
            `event/join/${route.params.id}`
        );
        if(r.status===201){
          console.log("joined")
          await getSalonData();

        } else if(r.status===207) {
          console.log("error: " + r.data.message)
        }
      } catch (e) {
        console.log(e);
      }
    }
    async function leaveSalon() {
      console.log("leave clicked")
      try {
        const r = await customAxios.getWithAuth(
            `event/leave/${route.params.id}`
        );
        if(r.status===202){
          console.log("leaved")
          await getSalonData();

        } else if(r.status===207) {
          console.log("error: " + r.data.message)
        }
      } catch (e) {
        console.log(e);
      }
    }

    async function checkRoles(){
      try {
        const r = await customAxios.getWithAuth(
            `person/i`
        );
        if(r.status===200){
          r.data.roles.forEach(item=>{
            if(item==="ROLE_ADMIN"){
              isAdmin.value = true;
            }
          })
        }
      } catch (e) {
        console.log(e);
      }
    }


    onMounted(async () => {
      await checkRoles();
      await getSalonData();
      loadingStatus.value = "loaded";

    });

    return { t,
      menuIsShowed,
      currentSalon,
      joinToSalon,
      leaveSalon,
      closeVideoModal,
      video,
      showVideo,
      selectVideo,
      SalonVideoPreview,
      NVideoModal,
      currentId,
      setCurrentId,
      loadingStatus
    };
  },
};
</script>

<style lang="scss">
.webinar-layout {
  &__wrapper {
    @include page;
  }
}


.salon-data {
  text-align: center;
}

.salon-data ul {

  padding: 10px !important;
  margin-left: 40px;
  text-align: justify;
}

.salon-data li {
  padding-top: 20px !important;
  text-align: justify;
}

.salon-data p {
  padding-left: 20px !important;
  text-align: justify;
}
.salon-data h1 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
  text-align: center;
}
.salon-data h2 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
  text-align: center;
}

.salon-data h3 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
  text-align: center;
  font-size: 30px;
  line-height: 40px;
}

.salon-data h4 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
  text-align: center;
}

.salon-data h5 {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
  text-align: center;
}

.salon-data  img {
  max-width: 80% !important;
  margin: auto;
  display: block;
}


.salon-data >>> blockquote {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

.salon-data >>> blockquote p {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

.salon-data >>> blockquote::after {
  content: "\201C";
}

.salon-data >>> blockquote::before {
  content: "\201D";
}

.salon-data >>> pre {
  padding: 15px;
  background: rgba(104, 99, 255, 0.96);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
