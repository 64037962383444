<template>
    <AppButton class="app-scroller">
        <AppIcon icon="arrow-icon" />
    </AppButton>
</template>

<script>
import AppButton from './AppButton.vue';
import AppIcon from './AppIcon.vue';

export default {
    setup() {
        return {};
    },
    components: { AppButton, AppIcon }
};
</script>

<style lang="scss">
.app-scroller {
    width: 50px;
    height: 50px;
    @include dashed-button;

    position: fixed;
    z-index: 300;
    top: 50%;
    right: 1%;
    @include flex-center;
    border-color: $accent-1;

    &::after {
        background-color: $accent-1;
    }

    svg {
        stroke: #fff;
        transform: rotate(-90deg);
    }
}
</style>