<template>
  <div class="n-contacts wrapper flex">
    <p>{{ t("contact.us") }}:</p>

    <a
      v-if="contacts.phone"
      class="flex flex__ai-c"
      :href="`tel:${contacts.phone}`"
    >
      <AppIcon icon="phone-icon" />
      <span>{{ contacts.phone }}</span>
    </a>

    <a
      v-if="contacts.email"
      class="flex flex__ai-c"
      :href="`mailto:${contacts.email}`"
    >
      <AppIcon icon="mail-icon" />
      <span>{{ contacts.email }}</span>
    </a>

    <p v-if="withAddress">
      {{ contacts.address }}
    </p>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import AppIcon from "@/elements/AppIcon";

export default {
  components: { AppIcon },
  props: {
    contacts: {
      type: Object,
      required: true,
    },
    withAddress: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.n-contacts {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  align-items: center;
  color: $text;

  a {
    @include link-default;
  }

  svg {
    stroke: $accent-1;
  }

  a:hover svg {
    animation: rotate-right-left 0.2s infinite ease-in;
  }

  @media (max-width: 768px) {
    padding-top: 16px;
    padding-bottom: 16px;

    span {
      display: none;
    }

    a {
      @include flex-center;
      padding: 0;
      width: 50px;
      height: 50px;
      border: 1px dashed $accent-1;
      border-radius: 50%;
    }

    a + a {
      margin-left: 16px;
    }

    p {
      margin-right: 16px;
    }
  }
}
</style>